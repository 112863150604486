/**
 * @property {int} id
 * @property {string} title
 * @property {boolean} isEnabled
 * @property {Array.<String>|null} value
 * @property {string|null} errorMessage
 * @property {Array.<DeactivationCodeColumn>} columns
 */
import JsonObject from "../utils/json/JsonObject";

class DeactivationCodeObject {
    constructor() {
        this.id = 0;
        this.title = "";
        this.isEnabled = false;
        this.value = null;
        this.errorMessage = null;
        this.columns = [];
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.id = jsonObject.int("id");
        this.title = jsonObject.stringDefault("title", "");
        this.isEnabled = jsonObject.boolean("isEnabled");
        this.errorMessage = jsonObject.stringNull("errorMessage");

        const valueJsonArray = jsonObject.arrayNull("value");
        this.value = [];
        if(valueJsonArray) {
            valueJsonArray.forEachIndexed((index) => {
                this.value.push(valueJsonArray.getString(index));
            });
        }

        const columnsJsonArray = jsonObject.array("columns");
        this.columns = [];
        columnsJsonArray.jsonData.forEach((columnJsonData) => {
            const columnJsonObject = new JsonObject(columnJsonData);
            this.columns.push(DeactivationCodeColumn.parse(columnJsonObject));
        });
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {DeactivationCodeObject}
     */
    static parse(jsonObject) {
        const deactivationCodeObject = new DeactivationCodeObject();
        deactivationCodeObject.parse(jsonObject);
        return deactivationCodeObject;
    }
}

/**
 * @property {Array.<String>} characters
 * @property {Array.<int>} crossedCharacters
 */
export class DeactivationCodeColumn {
    constructor() {
        this.characters = []
        this.crossedCharacters = []
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        const charactersJsonArray = jsonObject.array("characters");
        this.characters = [];
        charactersJsonArray.forEachIndexed((index) => {
            this.characters.push(charactersJsonArray.getString(index));
        });

        const crossedCharactersJsonArray = jsonObject.array("crossedCharacters");
        this.crossedCharacters = [];
        crossedCharactersJsonArray.forEachIndexed((index) => {
            this.crossedCharacters.push(crossedCharactersJsonArray.getInt(index));
        });
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {DeactivationCodeColumn}
     */
    static parse(jsonObject) {
        const deactivationCodeColumn = new DeactivationCodeColumn();
        deactivationCodeColumn.parse(jsonObject);
        return deactivationCodeColumn;
    }
}

export default DeactivationCodeObject;