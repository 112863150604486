import React, {useEffect, useRef, useState} from "react";
import './Home.scss'
import {useHistory} from "react-router-dom";
import ErrorView from "../../components/error-view/ErrorView";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import {HomeRequest} from "./HomeRequest";
import NavigationBar from "../../components/navigation-bar/NavigationBar";

function Home() {
    const history = useHistory();
    const homeRequest = useRef(null);
    const [homeData, setHomeData] = useState(/** @var {RouteObject|null} */ null);
    const [requestError, setRequestError] = useState(null);

    useEffect(() => {
        if(!homeData && !requestError) {
            homeRequest.current?.cancel();
            homeRequest.current = new HomeRequest();
            homeRequest.current.onSuccess = (routeObject) => {
                setHomeData(routeObject);
            }
            homeRequest.current.onFailure = (error) => {
                setRequestError(true);
            }
            homeRequest.current.enqueue();

            return () => {
                //Happens when component is dismounted, so cancel request
                homeRequest.current?.cancel();
            }
        }
    }, [homeData, requestError])

    return (
        <div id="home" className="main">
            <div className="page-content-wrapper">
                <Content />
            </div>
        </div>
    );

    function Content() {
        if(requestError) {
            return <ErrorView
                retryOnClick={() => {
                    setRequestError(null);
                }}
            />;
        } else if(homeData) {
            return <FilledContent />;
        } else {
            return <LoadingIndicator
                indicatorColor={"dark"}
                showBackgroundOverlay={false}
                isFullscreen={false}
                isLoading={true}
            />
        }
    }

    function FilledContent() {
        let typeString = "";
        switch(homeData.type) {
            case "murder":
                typeString = "Moordroute"; break;
            case "treasure":
                typeString = "Schattenjacht"; break;
            case "spy":
                typeString = "Spionmissie"; break;
        }

        return (
            <React.Fragment>
                <div className="cover-image" style={{backgroundImage: 'url(' + homeData.imageUrl + ')'}} />
                <NavigationBar title="&nbsp;"
                               transparent={true}
                    // leftTitle={"Terug"}
                    // leftIcon={"back"}
                    // leftIconClick={() => {
                    //     history.goBack();
                    // }}
                />
            <div className="page-content">
                <div className="top-container">
                    <div className="row with-small-padding">
                        <div className="badges">
                            <div className="badge distance">{homeData.distance}</div>
                            <div className="badge duration">{homeData.duration}</div>
                        </div>
                        <div className="card">
                            <h1>{homeData.city}</h1>
                            <div className="type">{typeString}</div>
                            <div className="text" dangerouslySetInnerHTML={{__html: homeData.text}} />
                        </div>
                    </div>
                </div>
                <div className="bottom-container">
                    <div className="row with-small-padding">
                        <button className="full-width" onClick={() => { history.push('/step/' + homeData.firstStepId) }}>Route starten</button>
                    </div>
                </div>
            </div>
            </React.Fragment>
        )
    }
}

export default Home;