import GalleryData from "../../models/GalleryData";
import JsonError from "../../utils/json/JsonError";

/**
 * @property {boolean} success
 * @property {boolean} skipFeedbackPage
 * @property {string} title
 * @property {string} subtitle
 * @property {string} thanksText
 * @property {string} solutionsLink
 * @property {GalleryData|null} gallery
 * @property {Array.<String>|null} deactivationCodeValue
 */
class SolutionObject {
    constructor() {
        this.success = false;
        this.skipFeedbackPage = false;
        this.title = "";
        this.subtitle = "";
        this.thanksText = "";
        this.solutionsLink = "";
        this.gallery = null;
        this.deactivationCodeValue = null;
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.success = jsonObject.boolean("succeeded");
        this.skipFeedbackPage = jsonObject.booleanDefault("skipFeedbackPage", false);
        this.title = jsonObject.stringDefault("title", "");
        this.subtitle = jsonObject.stringDefault("subtitle", "");
        this.thanksText = jsonObject.stringDefault("thanksText", "");
        this.solutionsLink = jsonObject.string("solutionsLink");

        const galleryJson = jsonObject.arrayNull("gallery");
        if(galleryJson) {
            const galleryData = new GalleryData();
            galleryData.title = "Oplossing";
            galleryData.parseItems(galleryJson);
            this.gallery = galleryData;
        }

        const deactivationCodeJsonArray = jsonObject.arrayNull("deactivationCodeValue");
        this.deactivationCodeValue = [];
        if(deactivationCodeJsonArray) {
            deactivationCodeJsonArray.forEachIndexed((index) => {
                this.deactivationCodeValue.push(deactivationCodeJsonArray.getString(index));
            });
        }

        if(!this.gallery && !this.deactivationCodeValue) {
            throw new JsonError("Json contains no gallery or deactivationCodeValue");
        }
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {SolutionObject}
     */
    static parse(jsonObject) {
        const solutionObject = new SolutionObject();
        solutionObject.parse(jsonObject);
        return solutionObject;
    }
}

export default SolutionObject;