import Api from "./Api";
import axios from "axios";
import AppStorage from "../utils/AppStorage";
import Popup from "../components/popup/Popup";
import {AppUtils} from "../utils/AppUtils";

export class BaseRequest {
    constructor() {
        this.cancelSource = axios.CancelToken.source();
    }

    /**
     * @return AxiosRequestConfig
     */
    createRequest() {
        //Override in child
    }

    /**
     * @return {Object}
     */
    appUserHeaders() {
        return {
            AppGUID: AppStorage.appGuid()
        }
    }

    /**
     * @return {Object}
     */
    appUserAccessTokenHeaders() {
        const headers = this.appUserHeaders();
        headers.AccessToken = AppStorage.accessToken();
        return headers;
    }

    enqueue() {
        const thisRequest = this;
        const requestConfig = this.createRequest();
        if(!requestConfig.cancelToken) {
            requestConfig.cancelToken = this.cancelSource.token;
        }
        Api.manager.request(requestConfig)
           .then(function (response) {
               try {
                   thisRequest.handleSuccess(response);
               } catch (error) {
                   thisRequest.handleFailure(error);
               }
           })
           .catch(function (error) {
               if(axios.isCancel(error)) {
                   thisRequest.handleCancelled(error)
               } else {
                   if(error.response?.status === 401) {
                       thisRequest.handleUnauthorized(error);
                   } else {
                       thisRequest.handleFailure(error);
                   }
               }
           })
    }

    /**
     * @param {AxiosResponse<any>} response
     */
    handleSuccess(response) {
        //Override in child
    }

    /**
     * @param {AxiosError<any>} error
     */
    handleFailure(error) {
        //Override in child
    }

    handleCancelled(error) {
        //Override in child
    }

    /**
     * @param {AxiosError<any>} error
     */
    handleUnauthorized(error) {
        AppStorage.setAppGuidShouldUpdate(true);
        const config = {
            title: "Code niet geldig",
            text: "De code is niet meer geldig, dus je wordt nu uitgelogd.",
            confirmButtonText: "OK",
            cancelOutside: false
        };
        Popup.show(config, () => {
            AppUtils.closeRoute();
        });
    }

    cancel() {
        this.cancelSource.cancel("Cancel method called");
    }
}