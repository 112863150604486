/**
 * @property {int} id
 * @property {string} type
 * @property {string} title
 * @property {boolean} hasAnswer
 * @property {string} chosenImageUrl
 */
class MapItem {

    constructor() {
        this.id = 0;
        this.type = "";
        this.title = "";
        this.hasAnswer = false
        this.chosenImageUrl = ""
    }

    isClue() {
        return this.type === "clue";
    }

    isFact() {
        return this.type === "fact";
    }

    isSecret() {
        return this.type === "info";
    }

    /**
     * @param {int} id
     * @param {string} type
     * @param {string} title
     * @param {string} chosenImageUrl
     * @return {MapItem}
     */
    static create(id, type, title, chosenImageUrl = "") {
        let mapItem = new MapItem();
        mapItem.id = id;
        mapItem.type = type;
        mapItem.title = title;
        mapItem.chosenImageUrl = chosenImageUrl
        return mapItem;
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.id = jsonObject.int("id");
        this.title = jsonObject.stringDefault("title", "");
        this.type = jsonObject.string("type");
        this.hasAnswer = jsonObject.booleanDefault("hasAnswer", false);
        this.chosenImageUrl = jsonObject.stringNull("chosenImageUrl");
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {MapItem}
     */
    static parse(jsonObject) {
        const mapItem = new MapItem();
        mapItem.parse(jsonObject);
        return mapItem;
    }
}

export default MapItem;