import JsonError from "./JsonError";
import JsonArray from "./JsonArray";
import JsonUtils from "./JsonUtils";

class JsonObject {
    constructor(json) {
        if(!JsonObject.isJsonObject(json)) {
            throw new JsonError("This is not a JsonObject");
        }

        this.jsonData = json;
    }

    /**
     * @param {string} name
     * @param {JsonObject} defaultValue
     * @returns {JsonObject}
     */
    objDefault(name, defaultValue) {
        const jsonValue = this.objNull(name);
        return (jsonValue === null) ? defaultValue : jsonValue;
    }

    /**
     * @param {string} name
     * @returns {JsonObject|null}
     */
    objNull(name) {
        try {
            return this.obj(name);
        } catch(e) {
            return null;
        }
    }

    /**
     * @param {string} name
     * @returns {JsonObject}
     * @throws
     */
    obj(name) {
        const jsonValue = this.jsonData[name];
        if(!JsonObject.isJsonObject(jsonValue)) {
            throw new JsonError(name + " is not a JsonObject");
        }
        return new JsonObject(jsonValue);
    }

    /**
     * @param {string} name
     * @param {JsonArray} defaultValue
     * @returns {JsonArray}
     */
    arrayDefault(name, defaultValue) {
        const jsonValue = this.arrayNull(name);
        return (jsonValue === null) ? defaultValue : jsonValue;
    }

    /**
     * @param {string} name
     * @returns {JsonArray|null}
     */
    arrayNull(name) {
        try {
            return this.array(name);
        } catch(e) {
            return null;
        }
    }

    /**
     * @param {string} name
     * @returns {JsonArray}
     * @throws
     */
    array(name) {
        const jsonValue = this.jsonData[name];
        if(!JsonArray.isJsonArray(jsonValue)) {
            throw new JsonError(name + " is not a JsonArray");
        }
        return new JsonArray(jsonValue);
    }

    /**
     * @param {string} name
     * @param {string} defaultValue
     * @returns {string}
     */
    stringDefault(name, defaultValue) {
        const jsonValue = this.stringNull(name);
        return (jsonValue === null) ? defaultValue : jsonValue;
    }

    /**
     * @param {string} name
     * @returns {null|string}
     */
    stringNull(name) {
        try {
            return this.string(name);
        } catch(e) {
            return null;
        }
    }

    /**
     * @param {string} name
     * @returns {string}
     * @throws
     */
    string(name) {
        return JsonUtils.string(this.jsonData[name], name);
    }

    /**
     * @param {string} name
     * @param {int} defaultValue
     * @returns {int}
     */
    intDefault(name, defaultValue) {
        const jsonValue = this.intNull(name);
        return (jsonValue === null) ? defaultValue : jsonValue;
    }

    /**
     * @param {string} name
     * @returns {null|int}
     */
    intNull(name) {
        try {
            return this.int(name);
        } catch(e) {
            return null;
        }
    }

    /**
     * @param {string} name
     * @returns {int}
     * @throws
     */
    int(name) {
        return JsonUtils.int(this.jsonData[name], name);
    }

    /**
     * @param {string} name
     * @param {boolean} defaultValue
     * @returns {boolean}
     */
    booleanDefault(name, defaultValue) {
        const jsonValue = this.booleanNull(name);
        return (jsonValue === null) ? defaultValue : jsonValue;
    }

    /**
     * @param {string} name
     * @returns {null|boolean}
     */
    booleanNull(name) {
        try {
            return this.boolean(name);
        } catch(e) {
            return null;
        }
    }

    /**
     * @param {string} name
     * @returns {boolean}
     * @throws
     */
    boolean(name) {
        let jsonValue = this.jsonData[name];
        if(!JsonUtils.isNullOrUndefined(jsonValue)) {
            if(typeof jsonValue === 'boolean') {
                return jsonValue;
            }
            if(typeof jsonValue === 'string') {
                const tempNumber = Number(jsonValue);
                if(isNaN(tempNumber)) {
                    if(jsonValue.toLowerCase() === "true") {
                        return true;
                    } else if(jsonValue.toLowerCase() === "false") {
                        return false;
                    } else {
                        throw new JsonError(name + " is not a boolean");
                    }
                } else {
                    jsonValue = tempNumber; //Will be parsed in next if
                }
            }
            if(typeof jsonValue === 'number' && !isNaN(jsonValue)) {
                return jsonValue !== 0;
            }
        }
        throw new JsonError(name + " is not a boolean");
    }

    /**
     * @param json
     * @returns {boolean}
     */
    static isJsonObject(json) {
        return (json !== null && typeof json === 'object');
    }
}

export default JsonObject;