import React from "react";
import Icon from "./Icon";

function IconButton(props) {
    return (
        <button className={"smaller icon" + (props.className ? " " + props.className : "")} onClick={props.onClick} title={props.title} disabled={props.disabled ?? false}>
            <Icon icon={props.icon} alt={props.title} />
        </button>
    )
}

export default IconButton;