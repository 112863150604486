import React from "react";
import './GalleryView.scss'

function GalleryView(props) {
    const galleryItems = props.items;
    const isEnabled = props.isEnabled !== false; //This will also work when isEnabled is not in props

    /**
     * @param {GalleryItem} galleryItem
     */
    const onItemClick = (galleryItem) => {
        if(isEnabled && props.onItemClick) {
            props.onItemClick(galleryItem);
        }
    }

    let galleryList = [];
    for(let galleryItemIndex = 0; galleryItemIndex < galleryItems.length; galleryItemIndex++) {
        /** @var {GalleryItem} */
        let galleryItem = galleryItems[galleryItemIndex];
        const galleryItemGreyedOut = (!isEnabled) ? true : galleryItem.greyedOut;
        //Key is needed, otherwise we get warning
        galleryList.push(<div key={galleryItem.id} className={"gallery-item" + (galleryItem.crossed ? " crossed" : "") + ((!isEnabled) ? " disabled" : "")} onClick={() => { onItemClick(galleryItem) }}>
            <div className={"image-container"}>
                <div className={"image"} style={{backgroundImage: 'url(' + galleryItem.imageUrl + ')'}} />
                {galleryItem.crossed && <div className={"cross" + ((galleryItemGreyedOut) ? " greyed-out" : "")} />}
                {galleryItem.jokerUsed && <div className={"joker-used" + ((galleryItemGreyedOut) ? " greyed-out" : "")} />}
            </div>
            <div className={"title-container"}>
                <div className={"title"}>{galleryItem.title}</div>
                {galleryItem.subtitle && <div className={"subtitle"}>{galleryItem.subtitle}</div>}
            </div>
        </div>)
    }

    return (
        <div className={"gallery-view"}>
            {galleryList}
        </div>
    );
}

export default GalleryView;