import React from "react";
import './ErrorView.scss'

function ErrorView(props) {
    return (
        <div className={"error-view"}>
            <div className={"error-view-content"}>
                <div className={"title"}>{(props.title) ? props.title : "Fout opgetreden"}</div>
                <div className={"text"}>{(props.text) ? props.text : "Er is een fout opgetreden. Controleer je internetverbinding en probeer het opnieuw."}</div>
                { props.retryOnClick && <button className={"retry-button"} onClick={props.retryOnClick}>Opnieuw</button> }
            </div>
        </div>
    );
}

export default ErrorView;