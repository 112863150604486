import React, {useEffect, useRef, useState} from "react";
import './FeedbackPage.scss'
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import Popup from "../../components/popup/Popup";
import {useHistory} from "react-router-dom";
import {FeedbackPostRequest} from "./FeedbackPostRequest";
import {FeedbackRequest} from "./FeedbackRequest";
import ErrorView from "../../components/error-view/ErrorView";

function FeedbackPage() {
    const history = useHistory();
    const feedbackRequest = useRef(null);
    const feedbackPostRequest = useRef(null);
    const [feedbackData, setFeedbackData] = useState(/** @type {FeedbackData|null} */null);
    const [requestError, setRequestError] = useState(null);
    let setLoadingFunc = null;

    useEffect(() => {
        return () => {
            //Happens when component is dismounted, so cancel request
            feedbackPostRequest.current?.cancel();
            feedbackPostRequest.current = null;
        }
    }, [])

    useEffect(() => {
        if(!feedbackData && !requestError) {
            feedbackRequest.current?.cancel();
            feedbackRequest.current = new FeedbackRequest();
            /** @param {FeedbackData} feedbackData */
            feedbackRequest.current.onSuccess = (feedbackData) => {
                feedbackRequest.current = null;
                if(feedbackData.shouldSkipFeedbackPage) {
                    //Skip to end if we should skip this page
                    history.push('/end');
                } else {
                    setFeedbackData(feedbackData);
                }
            }
            feedbackRequest.current.onFailure = (error) => {
                feedbackRequest.current = null;
                setRequestError(true);
            }
            feedbackRequest.current.enqueue();

            return () => {
                //Happens when component is dismounted, so cancel request
                feedbackRequest.current?.cancel();
                feedbackRequest.current = null;
            }
        }
    }, [feedbackData, requestError])

    return (
        <div id="feedback-page" className="main">
            <PageContent />
            <Loader />
        </div>
    );

    function PageContent() {
        if(requestError) {
            return <ErrorView
                retryOnClick={() => {
                    setRequestError(null);
                }}
            />;
        } else if(feedbackData) {
            return <FilledPageContent />;
        } else {
            return <LoadingIndicator
                indicatorColor={"dark"}
                showBackgroundOverlay={false}
                isFullscreen={true}
                isLoading={true}
            />
        }
    }

    function FilledPageContent() {
        return (
            <div className="page-content-scroller">
                <div className="page-content row with-padding">
                    <h1>Opmerkingen</h1>
                    <p>
                        We zijn benieuwd naar wat je van deze Speurroute vond.
                        Heb je tips of opmerkingen? Of wil je het door jou verzonnen motief voor de daad met ons delen? Doe dat dan hier.
                        Wil je niets kwijt? Dan kun je dit veld leeglaten.
                        <br /><br />
                        Je gegevens worden alleen gebruikt om n.a.v. je opmerkingen eventueel contact met je op te nemen.
                        Ze worden nooit gedeeld met anderen.
                    </p>
                    <div className={"form"}>
                        <label className="input-label" htmlFor="name">Naam</label>
                        <input type="text" id="name" name="name" />
                        <label className="input-label" htmlFor="email">E-mailadres</label>
                        <input type="email" id="email" name="email" />
                        <label className="input-label" htmlFor="comments">Opmerkingen</label>
                        <textarea name="comments" id="comments" />

                        <div className={"checkbox-container"}>
                            <input type="checkbox" id="terms" name="terms" />
                            <label className="checkbox-label" htmlFor="terms">Ik ga akkoord met de <a href={"https://www.speurroutes.nl/privacy-policy"} target={"_blank"}>privacyvoorwaarden</a> van Speurroutes NL.</label>
                        </div>
                        <div className={"checkbox-container"}>
                            <input type="checkbox" id="newsletter" name="newsletter" />
                            <label className="checkbox-label" htmlFor="newsletter">Ik wil de onregelmatig verschijnende nieuwsbrief graag ontvangen.</label>
                        </div>
                        {feedbackData.isSolutionCorrect && //Don't show lottery if solution was incorrect
                        <div className={"checkbox-container"}>
                            <input type="checkbox" id="lottery" name="lottery" />
                            <label className="checkbox-label" htmlFor="lottery">Ik wil meedoen met de jaarlijkse loterij en kans maken op 100 euro.</label>
                        </div>
                        }

                        <button id="button-continue" onClick={() => {
                            onContinueClick()
                        }}>
                            Verder
                        </button>
                    </div>
                </div>
                <div className="footer skyline" />
            </div>
        )
    }

    function Loader() {
        const [isLoading, setLoading] = useState(false);
        setLoadingFunc = setLoading;

        return <LoadingIndicator
            indicatorColor={"light"}
            showBackgroundOverlay={true}
            isFullscreen={true}
            isLoading={isLoading}
        />;
    }

    function onContinueClick() {
        function showErrorPopup(error, title = "Oeps") {
            const config = {
                title: title,
                text: error,
                confirmButtonText: "OK"
            };
            Popup.show(config)
        }

        let name = document.getElementById("name").value.trim();
        let email = document.getElementById("email").value.trim();
        let comments = document.getElementById("comments").value.trim();
        let isTermsChecked = document.getElementById("terms").checked;
        let isNewsletterChecked = document.getElementById("newsletter").checked;
        let isLotteryChecked = document.getElementById("lottery")?.checked ?? false;

        if(comments || isNewsletterChecked || isLotteryChecked) {
            if(!name || !email) {
                if(isNewsletterChecked) {
                    showErrorPopup("Om de nieuwsbrief te kunnen ontvangen moet je je naam en e-mailadres invullen.");
                } else if(isLotteryChecked) {
                    showErrorPopup("Om mee te kunnen doen met de Speurroutes Loterij moet je je naam en e-mailadres invullen.", "Speurroutes Loterij");
                } else {
                    showErrorPopup("Je hebt je naam of e-mailadres nog niet ingevuld.", "Let op");
                }
                return
            } else if(!isValidEmail(email)) {
                showErrorPopup("Het ingevulde e-mailadres is niet geldig.");
                return
            } else if(!isTermsChecked) {
                showErrorPopup("Je moet nog akkoord gaan met de voorwaarden.", "Let op");
                return
            }
        }

        setLoadingFunc(true);
        feedbackPostRequest.current?.cancel();
        feedbackPostRequest.current = new FeedbackPostRequest({
            name: name,
            email: email,
            comments: comments,
            terms: isTermsChecked,
            newsletter: isNewsletterChecked,
            lottery: isLotteryChecked
        });
        feedbackPostRequest.current.onSuccess = () => {
            feedbackPostRequest.current = null;
            setLoadingFunc(false);

            history.push("/end");
        }
        feedbackPostRequest.current.onFailure = (error) => {
            feedbackPostRequest.current = null;
            setLoadingFunc(false);

            Popup.showDefaultRequestError(() => {
                onContinueClick();
            })
        }
        feedbackPostRequest.current.enqueue();
    }

    /**
     * @param {String} email
     * @return {Boolean}
     */
    function isValidEmail(email) {
        const emailRegex = /^[a-zA-Z0-9\+\.\_\%\-\+]{1,256}\@[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}(\.[a-zA-Z0-9][a-zA-Z0-9\-]{0,25})+$/g;
        const emailRegexResult = emailRegex.exec(email);
        if(emailRegexResult === null || emailRegexResult.length <= 0) {
            return false;
        } else {
            return true;
        }
    }
}

export default FeedbackPage;