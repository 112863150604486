/**
 * @property {string} title
 * @property {boolean} isEnabled
 * @property {Array.<CombiboxColumn>} columns
 * @property {Array.<CombiboxValue>} values
 */
import JsonObject from "../utils/json/JsonObject";

class CombiboxObject {
    constructor() {
        this.title = "";
        this.isEnabled = false;
        this.columns = [];
        this.values = [];
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.title = jsonObject.stringDefault("title", "");
        this.isEnabled = jsonObject.boolean("isEnabled");

        const columnsJsonArray = jsonObject.array("columns");
        this.columns = [];
        columnsJsonArray.jsonData.forEach((columnJsonData) => {
            const columnJsonObject = new JsonObject(columnJsonData);
            this.columns.push(CombiboxColumn.parse(columnJsonObject));
        });

        const valuesJsonArray = jsonObject.array("values");
        this.values = [];
        valuesJsonArray.jsonData.forEach((valueJsonData) => {
            const valueJsonObject = new JsonObject(valueJsonData);
            this.values.push(CombiboxValue.parse(valueJsonObject));
        });
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {CombiboxObject}
     */
    static parse(jsonObject) {
        const combiboxObject = new CombiboxObject();
        combiboxObject.parse(jsonObject);
        return combiboxObject;
    }
}

/**
 * @property {Array.<String>} characters
 * @property {Array.<int>} crossedCharacters
 */
export class CombiboxColumn {
    constructor() {
        this.characters = []
        this.crossedCharacters = []
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        const charactersJsonArray = jsonObject.array("characters");
        this.characters = [];
        charactersJsonArray.forEachIndexed((index) => {
            this.characters.push(charactersJsonArray.getString(index));
        });

        const crossedCharactersJsonArray = jsonObject.array("crossedCharacters");
        this.crossedCharacters = [];
        crossedCharactersJsonArray.forEachIndexed((index) => {
            this.crossedCharacters.push(crossedCharactersJsonArray.getInt(index));
        });
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {CombiboxColumn}
     */
    static parse(jsonObject) {
        const combiboxColumn = new CombiboxColumn();
        combiboxColumn.parse(jsonObject);
        return combiboxColumn;
    }
}

/**
 * @property {String} label
 * @property {int} length
 * @property {boolean} isJokerUsed
 * @property {Array.<String>} value
 */
export class CombiboxValue {
    constructor() {
        this.label = ""
        this.length = 0
        this.isJokerUsed = false;
        this.value = []
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.label = jsonObject.stringDefault("label", "");
        this.length = jsonObject.int("length");
        this.isJokerUsed = jsonObject.booleanDefault("isJokerUsed", false);

        const valueJsonArray = jsonObject.arrayNull("value");
        this.value = [];
        valueJsonArray?.forEachIndexed((index) => {
            this.value.push(valueJsonArray.getString(index));
        });
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {CombiboxValue}
     */
    static parse(jsonObject) {
        const combiboxValue = new CombiboxValue();
        combiboxValue.parse(jsonObject);
        return combiboxValue;
    }
}

export default CombiboxObject;