import {BaseRequest} from "../../api/BaseRequest";
import JsonObject from "../../utils/json/JsonObject";
import AppStorage from "../../utils/AppStorage";
import {AppUtils} from "../../utils/AppUtils";

/**
 * @property {function|null} onSuccess
 * @property {function|null} onFailure
 * @property {string} code
 */
export class RedeemRequest extends BaseRequest {
    /**
     * @param {string} code
     */
    constructor(code) {
        super();

        this.onSuccess = null;
        this.onFailure = null;
        this.code = code;
    }

    createRequest() {
        return {
            method: 'post',
            url: '/redeemcode',
            // url: 'https://zooma.mockable.io/speurroutes/code-verzilveren',
            headers: this.appUserHeaders(),
            data: {
                token: this.code
            }
        }
    }

    handleSuccess(response) {
        try {
            const jsonObject = new JsonObject(response.data);
            AppStorage.setRouteType(jsonObject.string("routeType"));
            AppStorage.setAccessToken(jsonObject.string("accessToken"));
            let isRouteFinished = jsonObject.booleanDefault("isRouteFinished", false);

            this.onSuccess?.(isRouteFinished);
        } catch(error) {
            this.handleFailure(error);
        }
    }

    handleFailure(error) {
        this.onFailure?.(error);
    }

    //Override unauthorized, because we are not logged in here
    handleUnauthorized(error) {
        try {
            const jsonObject = new JsonObject(error.response?.data);
            //In case app guid is removed from server, tell to update it
            if(jsonObject.booleanDefault("isAppUserInvalid", false)) {
                AppStorage.setAppGuidShouldUpdate(true);
                AppUtils.setShouldUpdateAppGuid?.(true);
            } else {
                this.onFailure?.(error);
            }
        } catch(jsonError) {
            this.onFailure?.(error);
        }
    }
}