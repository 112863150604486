import NoteItem from "./NoteItem";
import JsonObject from "../../utils/json/JsonObject";

/**
 * @property {int} currentItemId
 * @property {boolean} isRouteFinished
 * @property {Array.<NoteItem>} items
 */
class NotesData {

    constructor() {
        this.currentItemId = 0
        this.isRouteFinished = false
        this.items = []
    }

    /**
     * @return {NotesData}
     */
    static fillTestData() {
        let notesData = new NotesData();
        notesData.currentItemId = 4
        notesData.items = [
            NoteItem.create(1, "Clue 1", "Het geboortejaar van Rembrandt van Rijn is 1606 en hij overleed in 1669."),
            NoteItem.create(2, "Clue 2", "De oude sokken zitten nog in de was."),
            NoteItem.create(3, "Clue 3", "De seinvlaggen spellen het woord: Kluis"),
            NoteItem.create(4, "Wegwijzer", "")
        ];
        return notesData;
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.currentItemId = jsonObject.intDefault("currentItemId", 0);
        this.isRouteFinished = jsonObject.boolean("isRouteFinished");
        const itemsJsonArray = jsonObject.array("items");
        this.items = [];
        itemsJsonArray.jsonData.forEach((itemJsonData) => {
            const itemJsonObject = new JsonObject(itemJsonData);
            this.items.push(NoteItem.parse(itemJsonObject));
        });
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {NotesData}
     */
    static parse(jsonObject) {
        const notesData = new NotesData();
        notesData.parse(jsonObject);
        return notesData;
    }
}

export default NotesData;