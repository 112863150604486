import JsonObject from "../utils/json/JsonObject";

/**
 * @property {string} skylineImageUrl
 * @property {Array.<InformationPage>} pages
 */
class InformationData {
    constructor() {
        this.skylineImageUrl = ""
        this.pages = [];
    }

    static fillTestData() {
        let subitem = new InformationPage();
        subitem.id = 1
        subitem.title = "Hoe werkt het?"
        subitem.imageUrl = "https://pbs.twimg.com/media/EYuG8DcUcAAGOKA?format=jpg&name=large"
        subitem.text = "Honk"

        let subitem2 = new InformationPage();
        subitem2.id = 2
        subitem2.title = "Route-informatie"
        subitem2.imageUrl = "https://memestatic.fjcdn.com/pictures/Antonio+is+best+animal+crossing+character_36cd8f_7668113.jpg"
        subitem2.text = "Snuit!"

        let subitem3 = new InformationPage();
        subitem3.id = 3
        subitem3.title = "Test"
        subitem3.imageUrl = "https://memestatic.fjcdn.com/pictures/Antonio+is+best+animal+crossing+character_36cd8f_7668113.jpg"
        subitem3.text = "Test"

        let informationData = new InformationData();
        informationData.pages = [subitem, subitem2, subitem3]
        return informationData;
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.skylineImageUrl = jsonObject.stringNull("skylineImageUrl");
        this.pages = [];
        const pagesJsonArray = jsonObject.array("items");
        pagesJsonArray.jsonData.forEach((itemJsonData) => {
            const pageJsonObject = new JsonObject(itemJsonData);
            const page = InformationPage.parse(pageJsonObject);
            this.pages.push(page);
        });
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {InformationData}
     */
    static parse(jsonObject) {
        const informationData = new InformationData();
        informationData.parse(jsonObject);
        return informationData;
    }
}

/**
 * @property {int} id
 * @property {string} title
 * @property {string} imageUrl
 * @property {string} text
 */
export class InformationPage {
    constructor() {
        this.id = 0
        this.title = ""
        this.imageUrl = ""
        this.text = ""
    }

    static fillTestData(pageId) {
        let page = new InformationPage();
        if (pageId === "1") {
            page.id = 1
            page.title = "Hoe werkt het?"
            page.imageUrl = "https://pbs.twimg.com/media/EYuG8DcUcAAGOKA?format=jpg&name=large"
            page.text = "Honk"
        } else if(pageId === "2") {
            page.id = 2
            page.title = "Route-informatie"
            page.imageUrl = "https://memestatic.fjcdn.com/pictures/Antonio+is+best+animal+crossing+character_36cd8f_7668113.jpg"
            page.text = "Snuit!"
        } else if(pageId === "3") {
            page.id = 3
            page.title = "Test"
            page.imageUrl = "https://memestatic.fjcdn.com/pictures/Antonio+is+best+animal+crossing+character_36cd8f_7668113.jpg"
            page.text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dignissim tincidunt efficitur. Fusce semper enim quis turpis cursus facilisis. Donec id lorem ante. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. In pharetra fringilla elit sit amet mollis. Etiam vitae metus est. Cras eleifend quam eget sem egestas, vitae eleifend risus porta. Aliquam erat volutpat. In posuere vehicula molestie. Curabitur facilisis odio ipsum, id ultrices massa vestibulum at. Cras id facilisis orci, eget accumsan neque. Aenean efficitur blandit pharetra. Donec eget ex augue. Pellentesque vel elit velit. Interdum et malesuada fames ac ante ipsum primis in faucibus.\n" +
                "\n" +
                "Maecenas tincidunt fringilla sem, ac ullamcorper est ultricies eu. Nunc turpis est, aliquet ac scelerisque eget, malesuada nec nisl. Sed non nulla ut ligula mattis ullamcorper. Pellentesque euismod commodo enim. Mauris justo dui, convallis vel placerat ut, tincidunt vel dui. Aliquam eu dui orci. Sed magna sem, rutrum quis nulla et, accumsan blandit velit. Duis egestas ligula magna, id maximus felis posuere eget. Etiam vehicula placerat dictum. Duis porttitor metus nec tincidunt ultrices. Vivamus dapibus finibus est sed tincidunt. Cras dapibus porttitor aliquam. Ut faucibus tortor varius nibh iaculis, sed porttitor nibh venenatis. Etiam molestie sem metus, vel malesuada dolor aliquet id.\n" +
                "\n" +
                "Donec quis faucibus erat. Cras quam arcu, faucibus sed finibus non, pulvinar et ligula. Aliquam at tellus ut nunc consectetur bibendum. Nullam egestas justo nibh, vel rhoncus eros tincidunt non. Nulla molestie faucibus venenatis. Proin et tempor ex. Sed scelerisque sed massa eget accumsan. Nullam id ornare lacus. Morbi sed nisi id nunc mollis sodales sit amet eget augue. Ut felis quam, luctus quis tincidunt quis, vestibulum vel eros. Sed elementum nec velit sit amet efficitur. Fusce accumsan molestie enim nec sodales.\n" +
                "\n" +
                "Cras dolor eros, porttitor et elit ultricies, consequat porttitor sem. Phasellus et orci imperdiet, vehicula nibh ac, molestie elit. Praesent in velit nibh. Maecenas mattis dolor enim, non aliquet libero tincidunt sed. Duis quis est sollicitudin, congue libero quis, viverra est. Pellentesque in ante interdum, auctor ligula ac, finibus dui. Suspendisse a ultrices dolor.\n" +
                "\n" +
                "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Phasellus at diam sed felis placerat malesuada. Cras lobortis lorem eget ante pulvinar, sit amet porta velit varius. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Morbi egestas, diam ut porttitor sollicitudin, felis sapien porta risus, vel posuere felis tellus et ex. Proin ornare pretium porta. Aliquam nec sapien tempor, tempor risus in, malesuada lacus. Morbi malesuada odio ac dignissim pretium. Aliquam condimentum placerat orci, et tincidunt risus malesuada non. Nulla aliquet pharetra pellentesque. Duis dolor risus, interdum ut ante vel, auctor mollis turpis. Pellentesque sodales vitae magna id molestie."
        }
        return page;
    }

    parse(jsonObject) {
        this.id = jsonObject.int("id");
        this.title = jsonObject.stringDefault("title", "");
        this.imageUrl = jsonObject.stringDefault("imageUrl", "");
        this.text = jsonObject.stringDefault("text", "");
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {InformationPage}
     */
    static parse(jsonObject) {
        const informationPage = new InformationPage();
        informationPage.parse(jsonObject);
        return informationPage;
    }
}

export default InformationData;