import {InformationPage} from "../../../models/InformationData";
import {BaseRequest} from "../../../api/BaseRequest";
import JsonObject from "../../../utils/json/JsonObject";

/**
 * @property {string} id
 * @property {function|null} onSuccess
 * @property {function|null} onFailure
 */
export class InformationDetailRequest extends BaseRequest {
    /**
     * @param {string} id
     */
    constructor(id) {
        super();

        this.id = id;
        this.onSuccess = null;
        this.onFailure = null;
    }

    createRequest() {
        return {
            method: 'get',
            url: '/info/' + this.id,
            // url: 'https://zooma.mockable.io/speurroutes/information/' + this.id,
            headers: this.appUserAccessTokenHeaders()
        }
    }

    handleSuccess(response) {
        try {
            const jsonObject = new JsonObject(response.data);
            const informationPage = InformationPage.parse(jsonObject);

            this.onSuccess?.(informationPage);
        } catch(error) {
            this.handleFailure(error);
        }
    }

    handleFailure(error) {
        this.onFailure?.(error);
    }
}