import React from "react";
import JsonObject from "../utils/json/JsonObject";

/**
 * @property {int} id
 * @property {string} title
 * @property {string} skylineImageUrl
 * @property {Array.<CodeAlphabetItem>} items
 */
class CodeAlphabetData {
    constructor() {
        this.id = 0;
        this.title = "";
        this.skylineImageUrl = "";
        this.items = [];
    }

    static fillTestData() {
        let item = new CodeAlphabetData();
        item.title = "Seinvlaggenalfabet";

        let subitem = new CodeAlphabetItem();
        subitem.title = "A"
        subitem.imageUrl = require("../resources/temp-flag.png").default

        let subitem2 = new CodeAlphabetItem();
        subitem2.title = "B"
        subitem2.imageUrl = require("../resources/temp-flag.png").default

        item.items = [subitem, subitem2]
        return item;
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.id = jsonObject.int("id")
        this.title = jsonObject.stringDefault("title", "");
        this.skylineImageUrl = jsonObject.stringDefault("skylineImageUrl", "");

        const itemsJsonArray = jsonObject.array("items");
        this.items = [];
        itemsJsonArray.jsonData.forEach((itemJsonData) => {
            const itemJsonObject = new JsonObject(itemJsonData);
            const item = CodeAlphabetItem.parse(itemJsonObject);
            this.items.push(item);
        });
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {CodeAlphabetData}
     */
    static parse(jsonObject) {
        const codeAlphabetData = new CodeAlphabetData();
        codeAlphabetData.parse(jsonObject);
        return codeAlphabetData;
    }
}

/**
 * @property {int} id
 * @property {string} title
 * @property {string} imageUrl
 */
export class CodeAlphabetItem {
    constructor() {
        this.id = 0
        this.title = ""
        this.imageUrl = ""
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.id = jsonObject.int("id")
        this.title = jsonObject.stringDefault("title", "");
        this.imageUrl = jsonObject.string("imageUrl");
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {CodeAlphabetItem}
     */
    static parse(jsonObject) {
        const codeAlphabetItem = new CodeAlphabetItem();
        codeAlphabetItem.parse(jsonObject);
        return codeAlphabetItem;
    }
}

export default CodeAlphabetData;