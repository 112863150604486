import JsonObject from "../utils/json/JsonObject";
import GalleryItem from "./GalleryItem";

/**
 * @property {string} title
 * @property {boolean} isEnabled
 * @property {Array.<GalleryItem>} items
 */
class GalleryData {

    constructor() {
        this.title = "";
        this.isEnabled = true;
        this.items = []
    }

    setGreyedOutExceptSelectedItem() {
        for(const item of this.items) {
            if(item.crossed && !item.isCrossedCurrentStep) {
                item.greyedOut = true;
            }
        }
    }

    /**
     * @return {boolean}
     */
    isJokerUsedAtSelectedItem() {
        for(const item of this.items) {
            if(item.isCrossedCurrentStep) {
                return item.jokerUsed
            }
        }

        return false;
    }

    /**
     * @param {string} title
     * @param {boolean} isEnabled
     * @param {Array.<GalleryItem>} items
     * @return {GalleryData}
     */
    static create(title, isEnabled, items) {
        let galleryData = new GalleryData();
        galleryData.title = title
        galleryData.isEnabled = isEnabled
        galleryData.items = items
        return galleryData;
    }

    /**
     * @param {JsonArray} jsonArray
     */
    parseItems(jsonArray) {
        this.items = [];
        jsonArray.jsonData.forEach((itemJsonData) => {
            const itemJsonObject = new JsonObject(itemJsonData);
            this.items.push(GalleryItem.parse(itemJsonObject));
        });
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.title = jsonObject.stringDefault("title", "");
        this.isEnabled = jsonObject.boolean("isEnabled");
        const itemsJsonArray = jsonObject.array("items");
        this.parseItems(itemsJsonArray);
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {GalleryData}
     */
    static parse(jsonObject) {
        const galleryData = new GalleryData();
        galleryData.parse(jsonObject);
        return galleryData;
    }
}

export default GalleryData;