import {BaseRequest} from "../../api/BaseRequest";
import JsonObject from "../../utils/json/JsonObject";
import StepObject from "../../models/StepObject";

/**
 * @property {function|null} onSuccess
 * @property {function|null} onFailure
 */
export class StepSaveRequest extends BaseRequest {
    /**
     * @param {string} id
     * @param {Object, Array} jsonData
     */
    constructor(id, jsonData) {
        super();

        this.id = id;
        this.jsonData = jsonData;
        this.onSuccess = null;
        this.onFailure = null;
    }

    createRequest() {
        return {
            method: 'post',
            url: '/step/' + this.id,
            // url: 'https://zooma.mockable.io/speurroutes/step/' + this.id + "/save",
            headers: this.appUserAccessTokenHeaders(),
            data: this.jsonData
        }
    }

    handleSuccess(response) {
        try {
            const jsonObject = new JsonObject(response.data);
            this.onSuccess?.(jsonObject);
        } catch(error) {
            this.handleFailure(error);
        }
    }

    handleFailure(error) {
        this.onFailure?.(error);
    }
}