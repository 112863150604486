import React, {useEffect, useReducer, useRef, useState} from 'react';
import {BrowserRouter as Router, Switch} from "react-router-dom";
import "./sass/main.scss";
import {AppUtils} from "./utils/AppUtils";
import {history} from "./utils/AppHistory";
import AppStorage from "./utils/AppStorage";
import LoadingIndicator from "./components/loading-indicator/LoadingIndicator";
import {CreateAppUserRequest} from "./CreateAppUserRequest";
import Popup from "./components/popup/Popup";
import BrowserNotSupportedScreen from "./app/browser-not-supported/BrowserNotSupportedScreen";

function App() {
    const appUserRequest = useRef(null);
    const [shouldUpdateAppGuid, setShouldUpdateAppGuid] = useState(AppStorage.shouldUpdateAppGuid());
    const [isBrowserNotSupportedShouldContinue, setIsBrowserNotSupportedShouldContinue] = useState(AppStorage.browserNotSupportedContinueAnyway());

    AppUtils.setShouldUpdateAppGuid = setShouldUpdateAppGuid;
    AppUtils.updateRoutesSwitch = null;

    useEffect(() => {
        if(shouldUpdateAppGuid) {
            createAppUser();

            return () => {
                //Happens when component is dismounted, so cancel request
                appUserRequest.current?.cancel();
            }
        }
    }, [shouldUpdateAppGuid]);

    if(!isBrowserNotSupportedShouldContinue) {
        let isSupportedBrowser = false;
        if(navigator.userAgent.match(/edge\//i) || navigator.userAgent.match(/edg\//i) || navigator.userAgent.match(/edgA\//i)) {
            //Edge
            isSupportedBrowser = true;
        } else if(navigator.userAgent.match(/chrome\//i) && navigator.vendor.match(/google/i)) {
            //Chrome
            isSupportedBrowser = true;
        } else if(navigator.userAgent.match(/firefox\//i)) {
            //Firefox
            isSupportedBrowser = true
        } else if(navigator.userAgent.match(/safari\//i) && navigator.vendor.match(/apple/i)) {
            //Safari
            //Not necessarily Safari on iOS. But all browsers are based on safari, so it doesn't matter
            isSupportedBrowser = true
        }

        if(!isSupportedBrowser) {
            return (
                <React.Fragment>
                    <StylesheetLink />
                    <div className="App">
                        <BrowserNotSupportedScreen continueAnywayClicked={() => {
                            setIsBrowserNotSupportedShouldContinue(true);
                        }}/>
                    </div>
                </React.Fragment>
            )
        }
    }

    if(shouldUpdateAppGuid) {
        return <LoadingIndicator
            indicatorColor={"dark"}
            showBackgroundOverlay={false}
            isFullscreen={true}
            isLoading={true}
        />
    }

    AppUtils.updateRoutes();

    return (
        <Router history={history}>
            <StylesheetLink />
            <div className="App">
                <RoutesSwitch />
            </div>
        </Router>
    );

    function StylesheetLink() {
        return (
            <link rel="stylesheet"
                  href="https://fonts.googleapis.com/css?family=Lato:regular,bold,italic|Droid+Sans:regular,bold|Roboto+Mono">
            </link>
        )
    }

    function RoutesSwitch() {
        //Is a way to force the react component to update
        const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
        AppUtils.updateRoutesSwitch = forceUpdate;

        return (
            <Switch>
                {AppUtils.routes}
            </Switch>
        );
    }

    function createAppUser() {
        appUserRequest.current?.cancel();
        appUserRequest.current = new CreateAppUserRequest();
        appUserRequest.current.onSuccess = () => {
            appUserRequest.current = null;
            setShouldUpdateAppGuid(AppStorage.shouldUpdateAppGuid());
        }
        appUserRequest.current.onFailure = (error) => {
            appUserRequest.current = null;
            Popup.show({
                    title: "Fout",
                    text: "Er is een fout opgetreden. Controleer je internetverbinding en probeer het opnieuw.",
                    confirmButtonText: "Opnieuw",
                    cancelOutside: false
                },
                () => {
                    createAppUser();
                }
            )
        }
        appUserRequest.current.enqueue();
    }
}

export default App;
