import React, {useEffect, useRef, useState} from "react";
import './Redeem.scss'
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import Popup from "../../components/popup/Popup";
import {useHistory} from "react-router-dom";
import {RedeemRequest} from "./RedeemRequest";
import {AppUtils} from "../../utils/AppUtils";
import AppStorage from "../../utils/AppStorage";

function Redeem(props) {
    const redeemCode = new URLSearchParams(props.location.search).get("lc") ?? "";
    const redeemRequest = useRef(/** @type {RedeemRequest} */ null);
    const inputRef = useRef(/** @type {HTMLInputElement|null} */ null);
    const history = useHistory();
    let prevInputValue = redeemCode;
    let setLoadingFunc = null;
    let enableRedeemButtonFunc = null;

    useEffect(() => {
        if(redeemCode) {
            redeemInputChanged();
        }

        return () => {
            //Happens when component is dismounted, so cancel request
            redeemRequest.current?.cancel();
        }
    }, [])

    return (
        <div id="redeem" className="main">
            <div className="page-content-scroller">
                <div className="page-content row with-padding">
                    <a href={"https://www.speurroutes.nl"} target={"_blank"} className={"logo-container"}>
                        <img alt="Speurroutes" className="logo" src={require('../../resources/logo.svg').default} />
                    </a>
                    <h1>Code verzilveren</h1>
                    <p>
                        Vul hier de code in die je via e-mail of bij een verkooppunt hebt ontvangen. Na het verzilveren van de code wordt de Speurroute op deze telefoon geopend.
                    </p>
                    <div className="form">
                        <input type="text"
                               id="redeem-code"
                               name="redeem-code"
                               placeholder="XX-XXX-3-XXXX-XXXX-XXXX"
                               defaultValue={redeemCode}
                               autoCapitalize={"characters"}
                               ref={inputRef}
                               onChange={(event) => {
                                   redeemInputChanged();
                               }}
                               onKeyDown={(event) => {
                                   const valueLength = event.currentTarget.value.length;
                                   if(event.key === "Enter" && valueLength > 0) {
                                       onRedeemClick();
                                   }
                               }}
                        />
                        <br />
                        <RedeemButton />
                        <p className={"bottom-note"}>Heb je nog geen code voor een Speurroute?<br/>Ga naar <a href={"https://www.speurroutes.nl/winkel"} target={"_blank"}>www.speurroutes.nl</a></p>
                    </div>
                </div>
                <div className="footer skyline" />
            </div>
            <Loader />
        </div>
    );

    function RedeemButton() {
        const [isEnabled, setEnabled] = useState(false);
        enableRedeemButtonFunc = setEnabled;

        return (
            <button id="button-redeem" disabled={!isEnabled} onClick={() => {
                onRedeemClick();
            }}>Code verzilveren</button>
        )
    }

    function Loader() {
        const [isLoading, setLoading] = useState(false);
        setLoadingFunc = setLoading;

        return <LoadingIndicator
            indicatorColor={"light"}
            showBackgroundOverlay={true}
            isFullscreen={true}
            isLoading={isLoading}
        />;
    }

    function redeemInputChanged() {
        const redeemInput = inputRef.current;
        if(redeemInput.value.length > 0) {
            if(redeemInput.value.length > 23) {
                redeemInput.value = redeemInput.value.substring(0, 23);
            }

            redeemInput.value = redeemInput.value.toUpperCase();
            enableRedeemButtonFunc(true);

            //Input added
            if(prevInputValue.length < redeemInput.value.length) {
                const valueLength = redeemInput.value.length;
                if(valueLength === 2 || valueLength === 6 || valueLength === 8 || valueLength === 13 || valueLength === 18) {
                    redeemInput.value = redeemInput.value + "-"
                }
            }
        } else {
            enableRedeemButtonFunc(false);
        }

        prevInputValue = redeemInput.value;
    }

    function onRedeemClick() {
        if(!inputRef.current.value) {
            return
        }

        setLoadingFunc(true);
        redeemRequest.current?.cancel();
        redeemRequest.current = new RedeemRequest(inputRef.current.value);
        redeemRequest.current.onSuccess = (isRouteFinished) => {
            redeemRequest.current = null;
            setLoadingFunc(false);

            function goToHome() {
                AppUtils.updateRoutes();
                history.push('/home');
            }

            if(isRouteFinished) {
                const config = {
                    title: "Read Only",
                    text: "Je hebt deze Speurroute al opgelost, de app is nu in read-only modus. Je kunt de Speurroute, de oplossingen en je notities bekijken, je kunt alleen geen antwoorden meer invoeren of oplossing insturen.<br /><br />Er zijn Speurroutes in nog veel plaatsen in het land. Bekijk ze op <a href=\"https://www.speurroutes.nl\" target=\"_blank\">www.speurroutes.nl</a>.<br />Tot Speurs!",
                    confirmButtonText: "OK"
                }
                Popup.show(config, () => {
                    AppStorage.setFinishedRoute(true);
                    goToHome();
                })
            } else {
                goToHome();
            }
        }
        /** @param {AxiosError<any>}error */
        redeemRequest.current.onFailure = (error) => {
            redeemRequest.current = null;
            setLoadingFunc(false);

            const statusCode = error.response?.status
            if(statusCode) {
                if(statusCode === 401) {
                    const config = {
                        title: "Code niet geldig",
                        text: "De verzilveringscode die je hebt ingevuld is niet (meer) geldig. Check of je de code goed hebt overgenomen. Of mogelijk heb je de code te vaak gebruikt. De code die je hebt ontvangen is maar op één telefoon tegelijkertijd te gebruiken. Kijk voor instructies op <a href=\"https://www.speurroutes.nl\" target=\"_blank\">www.speurroutes.nl</a>.",
                        confirmButtonText: "OK"
                    }
                    Popup.show(config)
                } else if(statusCode === 422) {
                    const config = {
                        title: "Code onjuist",
                        text: "De verzilveringscode die je hebt ingevuld is niet (meer) geldig. Check of je de code goed hebt overgenomen. Of mogelijk heb je de code te vaak gebruikt. De code die je hebt ontvangen is maar op één telefoon tegelijkertijd te gebruiken. Kijk voor instructies op <a href=\"https://www.speurroutes.nl\" target=\"_blank\">www.speurroutes.nl</a>.",
                        confirmButtonText: "OK"
                    }
                    Popup.show(config)
                } else {
                    Popup.showDefaultRequestError(() => {
                        onRedeemClick();
                    })
                }
            } else {
                Popup.showDefaultRequestError(() => {
                    onRedeemClick();
                })
            }
        }
        redeemRequest.current.enqueue();
    }
}

export default Redeem;