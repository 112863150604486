import React from "react";
import './AddToHomeScreen.scss'
import {Link, Redirect, useHistory} from "react-router-dom";
import AppStorage from "../../utils/AppStorage";

function AddToHomeScreen(props) {
    const history = useHistory();
    const redeemCode = new URLSearchParams(props.location.search).get("lc") ?? "";
    let text;
    if(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
        const isSafari = (
            navigator.userAgent.match(/Version\/[\d\.]+.*Safari/) &&
            !navigator.userAgent.match(/edgios\//i) && //Edge
            !navigator.userAgent.match(/fxios\//i) && //Firefox
            !navigator.userAgent.match(/crios\//i) //Chrome
        );

        if(isSafari) {
            text = <p>Voeg de app toe als icoontje op het beginscherm van je Apple-toestel. Met het icoontje kun je makkelijk de app weer in als je deze tussentijds hebt weggeklikt:<br/>
                <ul>
                    <li>Tik onder in dit scherm op de knop ‘Deel’ (vierkantje met het pijltje omhoog).</li>
                    <li>Scrol naar beneden en tik op ‘Zet op beginscherm’.</li>
                    <li>Vul de naam van de app in en tik op ‘Voeg toe’.</li>
                    <li>Het Speurroutes-icoontje staat nu op je beginscherm.</li>
                    <li>Tik op het icoontje en ga op pad!</li>
                </ul>
            </p>
        } else {
            text = <p>Voeg de app toe als icoontje op het beginscherm van je Apple-toestel.<br /><br />Dit is alleen mogelijk vanuit de Safari-browser. Druk op de deelknop van deze browser en tik op Kopieer. Open de Safari-browser en plak hier de koppeling. Vanuit Safari kun je Speurroutes toevoegen als app op het beginscherm van je Apple-toestel.</p>
        }
    } else if(/Android/i.test(navigator.userAgent)) {
        text = <p>Voeg de app toe als icoontje op het startscherm van je Android-toestel. Met het icoontje kun je makkelijk de app weer in als je deze tussentijds hebt weggeklikt:<br/>
            <ul>
                <li>Tik op de menuknop van je browser (de drie puntjes of streepjes in een van de hoeken van het scherm).</li>
                <li>Tik in het menu op ‘Toevoegen aan startscherm’.</li>
                <li>Vul de naam van de app in en tik op ‘Toevoegen’.</li>
                <li>Het Speurroutes-icoontje staat nu op je startscherm.</li>
                <li>Tik op het icoontje en ga op pad!</li>
            </ul>
        </p>
    } else {
        // If we don't know what device user is using, skip add to homescreen
        return <RedirectToRedeem />
    }

    //If we are in PWA we don't need to show add to homescreen anymore
    if(window.matchMedia('(display-mode: standalone)').matches) {
        return <RedirectToRedeem />
    }

    //If we already pressed maybe later, skip this page
    if(AppStorage.addToHomeScreenMaybeLater()) {
        return <RedirectToRedeem />
    }

    return (
        <div id="addtohomescreen" className="main">
            <div className={"page-content row with-padding"}>
                <h1>Welkom in de Speurroutes-app!</h1>
                {text}
                <p style={{ paddingTop: "20px" }}>Meer weten? Kijk op <a href={"https://www.speurroutes.nl"} target={"_blank"}>www.speurroutes.nl</a></p>
                <div className={"maybe-later-link"} onClick={maybeLaterClicked}>Misschien later</div>
            </div>
            <div className="footer skyline" />
        </div>
    );

    function RedirectToRedeem() {
        return <Redirect to={getRedeemUrl()} />
    }

    function maybeLaterClicked() {
        AppStorage.setAddToHomeScreenMaybeLater(true);
        history.push(getRedeemUrl());
    }

    function getRedeemUrl() {
        return "/redeem" + ((redeemCode) ? ("?lc=" + redeemCode) : "")
    }
}

export default AddToHomeScreen;