import React, {useEffect, useRef, useState} from "react";
import './SolutionPage.scss'
import {useHistory} from "react-router-dom";
import GalleryView from "../../components/gallery-view/GalleryView";
import AppStorage from "../../utils/AppStorage";
import {SolutionRequest} from "./SolutionRequest";
import ErrorView from "../../components/error-view/ErrorView";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import DeactivationCodeResultView from "../../components/deactivation-code-view/DeactivationCodeResultView";

function SolutionPage() {
    const history = useHistory();
    const routeType = AppStorage.routeType();

    const solutionRequest = useRef(null);
    const [solutionObject, setSolutionObject] = useState(/** @var {SolutionObject|null} */ null);
    const [requestError, setRequestError] = useState(null);

    useEffect(() => {
        if(!solutionObject && !requestError) {
            solutionRequest.current?.cancel();
            solutionRequest.current = new SolutionRequest();
            solutionRequest.current.onSuccess = (solutionObject) => {
                setSolutionObject(solutionObject);
            }
            solutionRequest.current.onFailure = (error) => {
                setRequestError(true);
            }
            solutionRequest.current.enqueue();

            return () => {
                //Happens when component is dismounted, so cancel request
                solutionRequest.current?.cancel();
            }
        }
    }, [solutionObject, requestError])

    return (
        <PageContent />
    );

    function PageContent() {
        if(requestError) {
            return (
                <div id="solution-page" className={"main"}>
                    <ErrorView
                        retryOnClick={() => {
                            setRequestError(null);
                        }}
                    />
                </div>
            );
        } else if(solutionObject) {
            return <FilledContent />;
        } else {
            return (
                <div id="solution-page" className={"main"}>
                    <LoadingIndicator
                        indicatorColor={"dark"}
                        showBackgroundOverlay={false}
                        isFullscreen={false}
                        isLoading={true}
                    />
                </div>
            );
        }
    }

    function FilledContent() {
        return (
            <div id="solution-page" className={"main" + ((solutionObject.success) ? " success" : " failed")}>
                <div className={"header skyline"} />
                <div className={"content row with-padding"}>
                    <RouteIcon />
                    <h1>{solutionObject.title}</h1>
                    <p dangerouslySetInnerHTML={{__html: solutionObject.subtitle}}/>
                    <SolutionCard />
                    <p dangerouslySetInnerHTML={{__html: solutionObject.thanksText}}/>
                    <a href={solutionObject.solutionsLink} target={"_blank"} className={"view-solutions-button button quaternary"}>Oplossingen bekijken</a>
                    <button className={"continue-button"} onClick={() => {
                        if(solutionObject.skipFeedbackPage) {
                            history.push("/end")
                        } else {
                            history.push("/feedback");
                        }
                    }}>Verder
                    </button>
                </div>
                <div className={"footer skyline"} />
            </div>
        );
    }

    function RouteIcon() {
        if(!solutionObject.success) {
            return null;
        }

        if(routeType === "treasure") {
            return <img src={require("../../resources/solution-page/solution_success_treasure_icon.svg").default} className={"route-icon"} />
        } else if(routeType === "spy") {
            return <img src={require("../../resources/solution-page/solution_success_spy_icon.svg").default} className={"route-icon"} />
        } else { //murder
            return <img src={require("../../resources/solution-page/solution_success_murder_icon.svg").default} className={"route-icon"} />
        }
    }

    function SolutionCard() {
        return (
            <div className={"card solution-gallery"}>
                <h3>Oplossing</h3>
                {solutionObject.gallery && <GalleryView items={solutionObject.gallery.items} />}
                {solutionObject.deactivationCodeValue && <DeactivationCodeResultView value={solutionObject.deactivationCodeValue} />}
            </div>
        );
    }
}

export default SolutionPage;