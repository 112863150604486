import SolutionsGridView from "../../../components/solutions-grid-view/SolutionsGridView";
import {useHistory} from "react-router-dom";
import {useState} from "react";
import jokerUsedImage from "../../../resources/joker_used.svg"

export function StepSolutionsGridCard(props) {
    const history = useHistory();
    /** @var {StepObject} */
    const step = props.step;
    const [data, setData] = useState(/** @type {SolutionsGridObject} */ step.solutionsGrid);

    props.stepUpdated(stepUpdated);

    if(!step.solutionsGrid) {
        return null;
    }

    return (
        <div className="card solutions-grid">
            <h3>{data.title}</h3>
            <SolutionsGridView
                item={data}
                isEnabled={data.isEnabled}
                onBlur={(typedText) => {
                    props.saveStep({
                        chosenLetters: typedText
                    }, () => {
                        stepUpdated();
                    })
                }} />
            <JokerUsed />
            <p className={"button-container"}>
                <button className={"inline-button"}
                        onClick={() => {
                            history.push('/overview#tab2')
                        }}
                >Naar combibox
                </button>
            </p>
        </div>
    );

    function JokerUsed() {
        if(!data.isJokerUsed) {
            return null;
        }

        return <div className={"joker-used-container"}>
            <img src={jokerUsedImage} className={"joker-used-image"} />
            <div className={"joker-used-text"}>Je hebt hier je joker gebruikt!</div>
        </div>;
    }

    function stepUpdated() {
        setData(step.solutionsGrid);
    }
}