import React, {useEffect, useRef, useState} from "react";
import './Overview.scss'
import NavigationBar from "../../components/navigation-bar/NavigationBar";
import MapView from "./MapView";
import GalleryView from "../../components/gallery-view/GalleryView";
import Popup from "../../components/popup/Popup";
import {useHistory} from "react-router-dom";
import {OverviewRequest} from "./OverviewRequest";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import ErrorView from "../../components/error-view/ErrorView";
import CombiboxView from "../../components/combibox-view/CombiboxView";
import {ChangeCombiboxRequest} from "./ChangeCombiboxRequest";

function Overview() {
    const history = useHistory();
    const overviewRequest = useRef(null);
    const changeCombiboxRequest = useRef(null);
    const [overviewData, setOverviewData] = useState(/** @type {OverviewData|null} */ null);
    const [requestError, setRequestError] = useState(null);
    const scrollingContainer = useRef(/** @type {HTMLElement} */ null)
    let setLoadingFunc = null;
    let setCombiboxObjectFunc = null;

    useEffect(() => {
        return () => {
            //Happens when component is dismounted, so cancel request
            changeCombiboxRequest.current?.cancel();
            changeCombiboxRequest.current = null;
        }
    }, [])

    useEffect(() => {
        if(!overviewData && !requestError) {
            overviewRequest.current?.cancel();
            overviewRequest.current = new OverviewRequest();
            overviewRequest.current.onSuccess = (overviewData) => {
                setOverviewData(overviewData);
            }
            overviewRequest.current.onFailure = (error) => {
                setRequestError(true);
            }
            overviewRequest.current.enqueue();

            return () => {
                //Happens when component is dismounted, so cancel request
                overviewRequest.current?.cancel();
            }
        }
    }, [overviewData, requestError])

    return (
        <div id="overview" className="main">
            <div className="page-content-wrapper">
                <NavigationBar title="Overzicht"
                               rightTitle={"Sluiten"}
                               rightIcon={"close"}
                               rightIconClick={() => {
                                   history.goBack();
                               }}
                />
                <Content />
            </div>
        </div>
    );

    function Content() {
        if(requestError) {
            return <ErrorView
                retryOnClick={() => {
                    setRequestError(null);
                }}
            />;
        } else if(overviewData) {
            return <FilledContent />;
        } else {
            return <LoadingIndicator
                indicatorColor={"dark"}
                showBackgroundOverlay={false}
                isFullscreen={false}
                isLoading={true}
            />
        }
    }

    function FilledContent() {
        const hashTabSelection = parseInt(window.location.hash?.replace("#tab", ""));
        const [activeTabIndex, setActiveTabIndex] = useState(isNaN(hashTabSelection) ? 0 : ((hashTabSelection > overviewData.tabs.length) ? 0 : Math.max(0, hashTabSelection - 1)));

        useEffect(() => {
            scrollingContainer.current.scrollTop = 0;
        }, [activeTabIndex])

        /** @var {OverviewTab} */
        let currentTab = overviewData.tabs[activeTabIndex];
        let currentTabType = currentTab.type
        let currentTabView = null;
        if(currentTabType === "map") {
            currentTabView = <MapView data={currentTab.tabContent} scrollingContainer={scrollingContainer} />
        } else if(currentTabType === "gallery") {
            currentTabView = <GalleryTab data={currentTab.tabContent} />
        } else if(currentTabType === "combibox") {
            currentTabView = <CombiboxTab data={currentTab.tabContent} />
        }

        return (
            <React.Fragment>
                <TabNavigation
                    activeTabIndex={activeTabIndex}
                    setActiveTabIndex={setActiveTabIndex}
                />
                <div className="page-content-scroller" ref={scrollingContainer}>
                    <div className={"page-content row with-small-padding"}>
                        <div className={"card"}>
                            {currentTabView}
                        </div>
                    </div>
                </div>
                { overviewData?.skylineImageUrl && <div className="footer skyline" style={{backgroundImage: 'url(' + overviewData.skylineImageUrl + ')'}} /> }
                <Loader />
            </React.Fragment>
        );
    }

    function Loader() {
        const [isLoading, setLoading] = useState(false);
        setLoadingFunc = setLoading;

        return <LoadingIndicator
            indicatorColor={"light"}
            showBackgroundOverlay={true}
            isFullscreen={true}
            isLoading={isLoading}
        />;
    }

    function TabNavigation(tabNavProps) {
        let tabElementList = [];
        for(let tabIndex = 0; tabIndex < overviewData.tabs.length; tabIndex++) {
            //Key is needed, otherwise we get warning
            tabElementList.push(<div key={tabIndex} className={"tab" + ((tabIndex === tabNavProps.activeTabIndex) ? " selected" : "")} onClick={() => { tabNavProps.setActiveTabIndex(tabIndex) }}>{overviewData.tabs[tabIndex].title}</div>)
        }

        return (
            <div className={"tab-navigation row with-small-padding" + ((overviewData.tabs.length === 2) ? " equal-widths" : "")}>
                {tabElementList}
            </div>
        );
    }

    function GalleryTab(galleryProps) {
        /** @var {GalleryData} */
        const data = galleryProps.data
        return (
            <GalleryView
                items={data.items}
                onItemClick={() => {
                    const config = {
                        title: "Wijzigen",
                        text: "Iets wegstrepen of wijzigen kan niet in het overzicht. Ga naar een clue om daar je antwoord te wijzigen.",
                        confirmButtonText: "OK"
                    };
                    Popup.show(config)
                }}
            />
        );
    }

    function CombiboxTab(combiboxProps) {
        const [combiboxObject, setCombiboxObject] = useState(/** @type {CombiboxObject} */ combiboxProps.data);
        setCombiboxObjectFunc = setCombiboxObject;

        return (
            <div className={"combibox-tab"}>
                <div className={"help-text"}>Streep de tekens uit de witte vakjes van je oplossing weg in de combibox. Aan het einde van de route houd je 5 tekens over die samen de deactivatiecode vormen!</div>
                <CombiboxView
                    item={combiboxObject}
                    onClick={changeCombibox}
                />
            </div>
        );
    }

    function changeCombibox(columnIndex, rowIndex) {
        setLoadingFunc(true);

        changeCombiboxRequest.current?.cancel();
        changeCombiboxRequest.current = new ChangeCombiboxRequest({
            column: columnIndex,
            index: rowIndex
        });
        changeCombiboxRequest.current.onSuccess = (combiboxObject) => {
            changeCombiboxRequest.current = null;
            setLoadingFunc(false);
            overviewData.changeCombibox(combiboxObject);
            setCombiboxObjectFunc(combiboxObject);
        }
        changeCombiboxRequest.current.onFailure = (error) => {
            changeCombiboxRequest.current = null;
            setLoadingFunc(false);
            Popup.showDefaultRequestError(() => {
                changeCombibox(columnIndex, rowIndex);
            })
        }
        changeCombiboxRequest.current.enqueue();
    }
}

export default Overview;