import AppStorage from "./utils/AppStorage";
import {BaseRequest} from "./api/BaseRequest";

/**
 * @property {String} appGuid
 * @property {function|null} onSuccess
 * @property {function|null} onFailure
 */
export class CreateAppUserRequest extends BaseRequest {
    constructor() {
        super();

        this.appGuid = AppStorage.getOrCreateAppGuid();
        this.onSuccess = null;
        this.onFailure = null;
    }

    createRequest() {
        return {
            method: 'post',
            url: '/appuser',
            data: {
                "appGUID": this.appGuid,
                "firmware": "N/A",
                "phoneType": "Web",
                "appVersionCode": process.env.REACT_APP_APP_VERSION,
                "appVersionName": process.env.REACT_APP_APP_VERSION_NAME
            }
        }
    }

    handleSuccess(response) {
        try {
            AppStorage.setAppGuid(this.appGuid);
            AppStorage.setAppGuidShouldUpdate(false);
            this.onSuccess?.();
        } catch(error) {
            this.handleFailure(error);
        }
    }

    handleFailure(error) {
        this.onFailure?.(error);
    }
}