import React, {useEffect, useRef, useState} from "react";
import './CodeAlphabet.scss'
import NavigationBar from "../../components/navigation-bar/NavigationBar";
import {useHistory} from "react-router-dom";
import {CodeAlphabetRequest} from "./CodeAlphabetRequest";
import ErrorView from "../../components/error-view/ErrorView";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";

function CodeAlphabet(props) {
    const history = useHistory();
    const codeAlphabetId = props.match.params.id;
    const codeAlphabetRequest = useRef(null);
    const [codeAlphabetData, setCodeAlphabetData] = useState(/** @type {CodeAlphabetData} */ null);
    const [requestError, setRequestError] = useState(null);

    useEffect(() => {
        if(!codeAlphabetData && !requestError) {
            codeAlphabetRequest.current?.cancel();
            codeAlphabetRequest.current = new CodeAlphabetRequest(codeAlphabetId);
            codeAlphabetRequest.current.onSuccess = (codeAlphabetData) => {
                setCodeAlphabetData(codeAlphabetData);
            }
            codeAlphabetRequest.current.onFailure = (error) => {
                setRequestError(true);
            }
            codeAlphabetRequest.current.enqueue();

            return () => {
                //Happens when component is dismounted, so cancel request
                codeAlphabetRequest.current?.cancel();
            }
        }
    }, [codeAlphabetData, requestError])

    return (
        <div id="code-alphabet" className="main">
            <div className="page-content-wrapper">
                <NavigationBar title={codeAlphabetData?.title}
                               rightTitle={"Sluiten"}
                               rightIcon={"close"}
                               rightIconClick={() => {
                                   history.goBack();
                               }}
                />
                <PageContent />
            </div>
        </div>
    );

    function PageContent() {
        if(requestError) {
            return <ErrorView
                retryOnClick={() => {
                    setRequestError(null);
                }}
            />;
        } else if(codeAlphabetData) {
            return <FilledContent />;
        } else {
            return <LoadingIndicator
                indicatorColor={"dark"}
                showBackgroundOverlay={false}
                isFullscreen={false}
                isLoading={true}
            />
        }
    }

    function FilledContent() {
        const galleryHtml = [];
        for(let i = 0; i < codeAlphabetData.items.length; i++) {
            /** @var {CodeAlphabetItem} */
            const item = codeAlphabetData.items[i];
            galleryHtml.push(
                <CodeAlphabetGalleryItem key={"code-alphabet-item-" + i} imageUrl={item.imageUrl} title={item.title} />
            )
        }

        return (
            <React.Fragment>
                <div className="page-content-scroller">
                    <div className={"page-content row with-small-padding"}>
                        <div className={"card code-alphabet"}>
                            <div className={"code-alphabet-gallery"}>
                                {galleryHtml}
                            </div>
                        </div>
                    </div>
                </div>
                { codeAlphabetData.skylineImageUrl && <div className="footer skyline" style={{backgroundImage: 'url(' + codeAlphabetData.skylineImageUrl + ')'}} /> }
            </React.Fragment>
        );
    }

    function CodeAlphabetGalleryItem(itemProps) {
        return <div className={"gallery-item"}>
            <img className={"image"} src={itemProps.imageUrl} alt={itemProps.title} />
            <div className={"title"}>{itemProps.title}</div>
        </div>;
    }
}

export default CodeAlphabet;