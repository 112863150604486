import React from "react";
import './DeactivationCodeView.scss'
import LetterBoxView from "../letter-box/LetterBoxView";
import DeactivationCodeResultView from "./DeactivationCodeResultView";

function DeactivationCodeView(props) {
    /** @var {DeactivationCodeObject} */
    const item = props.item;

    const columnsHtml = [];
    item.columns.forEach((column, index) => {
        let columnHtml = [];
        for(let i = 0; i < column.characters.length; i++) {
            columnHtml.push(
                <LetterBoxView key={"column-box-" + i} value={column.characters[i]} className={column.crossedCharacters.includes(i) ? "crossed" : null} />
            )
        }

        columnsHtml.push(
            <div key={"column-" + index} className={"column"}>
                {columnHtml}
            </div>
        )
    })

    return (
        <div className={"deactivation-code-view" + (!item.isEnabled ? " disabled" : "")}>
            <div className={"columns-container"}>
                {columnsHtml}
            </div>
            <div className={"value-container"}>
                <div className={"title"}>Deactivatiecode</div>
                <DeactivationCodeResultView value={item.value} />
                <div className={"error-message"}>
                    {item.errorMessage}
                </div>
            </div>
        </div>
    )
}

export default DeactivationCodeView;