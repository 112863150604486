/**
 * @property {int} id
 * @property {string} title
 * @property {boolean} isEnabled
 * @property {string} label
 * @property {int} length
 * @property {boolean} isJokerUsed
 * @property {Array.<String>|null} value
 * @property {Array.<int>} highlightedCharacters
 */
class SolutionsGridObject {
    constructor() {
        this.id = 0;
        this.title = "";
        this.isEnabled = false;
        this.label = "";
        this.length = 0;
        this.isJokerUsed = false;
        this.value = null;
        this.highlightedCharacters = [];
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.id = jsonObject.int("id");
        this.title = jsonObject.stringDefault("title", "");
        this.isEnabled = jsonObject.boolean("isEnabled");
        this.label = jsonObject.stringDefault("label", "");
        this.length = jsonObject.int("length");
        this.isJokerUsed = jsonObject.booleanDefault("isJokerUsed", false);

        const valueJsonArray = jsonObject.arrayNull("value");
        this.value = [];
        if(valueJsonArray) {
            valueJsonArray.forEachIndexed((index) => {
                this.value.push(valueJsonArray.getString(index));
            });
        }

        const highlightedCharactersJsonArray = jsonObject.array("highlightedCharacters");
        this.highlightedCharacters = [];
        highlightedCharactersJsonArray.forEachIndexed((index) => {
            this.highlightedCharacters.push(highlightedCharactersJsonArray.getInt(index));
        });
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {SolutionsGridObject}
     */
    static parse(jsonObject) {
        const solutionsGridObject = new SolutionsGridObject();
        solutionsGridObject.parse(jsonObject);
        return solutionsGridObject;
    }
}

export default SolutionsGridObject;