import React from "react";
import './CombiboxView.scss'
import LetterBoxView from "../letter-box/LetterBoxView";

function CombiboxView(props) {
    /** @var {CombiboxObject} */
    const item = props.item;

    const columnsHtml = [];
    item.columns.forEach((column, columnIndex) => {
        let columnHtml = [];
        for(let rowIndex = 0; rowIndex < column.characters.length; rowIndex++) {
            columnHtml.push(
                <LetterBoxView
                    key={"column-box-" + rowIndex}
                    value={column.characters[rowIndex]}
                    className={column.crossedCharacters.includes(rowIndex) ? "crossed" : null}
                    onClick={() => {
                        if(item.isEnabled) {
                            props.onClick?.(columnIndex, rowIndex)
                        }
                    }}
                />
            )
        }

        columnsHtml.push(
            <div key={"column-" + columnIndex} className={"column"}>
                {columnHtml}
            </div>
        )
    })

    const valuesHtml = [];
    for(let index = 0; index < item.values.length; index++) {
        /** @var {CombiboxValue} */
        const value = item.values[index];

        let valueHtml = [];
        for(let i = 0; i < value.length; i++) {
            valueHtml.push(
                <LetterBoxView key={"value-box-" + i} value={value.value?.[i] ?? ""} />
            )
        }

        valuesHtml.push(
            <div key={"value-" + index} className={"value" + ((value.isJokerUsed) ? " joker-used" : "")}>
                <div className={"label"}>{value.label}</div>
                <div className={"letters-container"}>{valueHtml}</div>
            </div>
        )
    }

    return (
        <div className={"combibox-view" + (!item.isEnabled ? " disabled" : "")}>
            <div className={"columns-container"}>
                {columnsHtml}
            </div>
            <div className={"values-container"}>
                {valuesHtml}
            </div>
        </div>
    )
}

export default CombiboxView;