import {BaseRequest} from "../../api/BaseRequest";
import JsonObject from "../../utils/json/JsonObject";
import SolutionObject from "./SolutionObject";

/**
 * @property {function|null} onSuccess
 * @property {function|null} onFailure
 */
export class SolutionRequest extends BaseRequest {
    constructor() {
        super();

        this.onSuccess = null;
        this.onFailure = null;
    }

    createRequest() {
        return {
            method: 'get',
            url: '/solution',
            // url: 'https://zooma.mockable.io/speurroutes/solution?success=true&routetype=murder-mystery',
            // url: 'https://zooma.mockable.io/speurroutes/solution?success=false&routetype=murder-mystery',
            // url: 'https://zooma.mockable.io/speurroutes/solution?success=true&routetype=treasure-hunt',
            // url: 'https://zooma.mockable.io/speurroutes/solution?success=false&routetype=treasure-hunt',
            // url: 'https://zooma.mockable.io/speurroutes/solution?success=true&routetype=spy-mission',
            // url: 'https://zooma.mockable.io/speurroutes/solution?success=false&routetype=spy-mission',
            headers: this.appUserAccessTokenHeaders()
        }
    }

    handleSuccess(response) {
        try {
            const jsonObject = new JsonObject(response.data);
            const solutionObject = SolutionObject.parse(jsonObject);

            this.onSuccess?.(solutionObject);
        } catch(error) {
            this.handleFailure(error);
        }
    }

    handleFailure(error) {
        this.onFailure?.(error);
    }
}