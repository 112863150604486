/**
 * @property {boolean} isSolutionCorrect
 * @property {boolean} shouldSkipFeedbackPage
 */
class FeedbackData {

    constructor() {
        this.isSolutionCorrect = false;
        this.shouldSkipFeedbackPage = false;
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.isSolutionCorrect = jsonObject.boolean("isSolutionCorrect");
        this.shouldSkipFeedbackPage = jsonObject.boolean("shouldSkipFeedbackPage");
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {FeedbackData}
     */
    static parse(jsonObject) {
        const feedbackData = new FeedbackData();
        feedbackData.parse(jsonObject);
        return feedbackData;
    }
}

export default FeedbackData;