import React from "react";
import './ImageViewer.scss'
import NavigationBar from "../../components/navigation-bar/NavigationBar";
import {PinchZoom} from "react-ivy-pinch-zoom";

function ImageViewer(props) {
    return (
        <div id="image-viewer" className="main">
            <div className="page-content-wrapper">
                <NavigationBar title="&nbsp;"
                               transparent={false}
                               rightTitle={"Sluiten"}
                               rightIcon={"close"}
                               rightIconClick={() => {
                                   props.closeClick?.();
                               }}
                />
                <PageContent />
            </div>
        </div>
    );

    function PageContent() {
        return (
            <div className="page-content">
                {props.imageUrl && (
                    <PinchZoom imgPath={props.imageUrl}
                               limitZoom={2}
                               overflow={"visible"} />
                )}
            </div>
        )
    }
}

export default ImageViewer;