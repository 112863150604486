import React, {useEffect, useRef, useState} from "react";
import './EndPage.scss'
import {useHistory} from "react-router-dom";
import {EndRequest} from "./EndRequest";
import ErrorView from "../../components/error-view/ErrorView";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import {AppUtils} from "../../utils/AppUtils";

function EndPage() {
    const history = useHistory();

    const endRequest = useRef(null);
    const [endObject, setEndObject] = useState(/** @var {EndObject|null} */ null);
    const [requestError, setRequestError] = useState(null);

    useEffect(() => {
        if(!endObject && !requestError) {
            endRequest.current?.cancel();
            endRequest.current = new EndRequest();
            endRequest.current.onSuccess = (endObject) => {
                endRequest.current = null;
                setEndObject(endObject);
            }
            endRequest.current.onFailure = (error) => {
                endRequest.current = null;
                setRequestError(true);
            }
            endRequest.current.enqueue();

            return () => {
                //Happens when component is dismounted, so cancel request
                endRequest.current?.cancel();
            }
        }
    }, [endObject, requestError])

    return (
        <div id="end-page" className="main">
            <div className="page-content-scroller">
                <div className="page-content row with-padding">
                    <PageContent />
                </div>
                <div className="footer skyline" />
            </div>
        </div>
    );

    function PageContent() {
        if(requestError) {
            return (
                <ErrorView
                    retryOnClick={() => {
                        setRequestError(null);
                    }}
                />
            );
        } else if(endObject) {
            return <FilledPageContent />;
        } else {
            return (
                <LoadingIndicator
                    indicatorColor={"dark"}
                    showBackgroundOverlay={false}
                    isFullscreen={true}
                    isLoading={true}
                />
            );
        }
    }

    function FilledPageContent() {
        return (
            <React.Fragment>
                <h1>{endObject.title}</h1>
                <div className={"text"} dangerouslySetInnerHTML={{__html: endObject.text}} />
                <div className={"buttons"}>
                    <a href={"https://www.speurroutes.nl"} target={"_blank"} className={"button quaternary"}>Naar Speurroutes.nl</a>
                    <button onClick={() => {
                        AppUtils.closeRoute();
                    }}>Afsluiten
                    </button>
                </div>
            </React.Fragment>
        );
    }
}

export default EndPage;