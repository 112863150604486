import DeactivationCodeView from "../../../components/deactivation-code-view/DeactivationCodeView";
import {useHistory} from "react-router-dom";

export function StepDeactivationCodeCard(props) {
    const history = useHistory();
    /** @var {DeactivationCodeObject} */
    const data = props.data;

    return (
        <div className="card deactivation-code">
            <h3>{data.title}</h3>
            <DeactivationCodeView item={data} isEnabled={data.isEnabled} />
            <EditButton />
        </div>
    );

    function EditButton() {
        if(!data.isEnabled) {
            return null;
        }

        return (
            <p className={"button-container"}>
                <button className={"inline-button"} onClick={() => {
                    history.push('/overview#tab2')
                }}>Aanpassen
                </button>
            </p>
        )
    }
}