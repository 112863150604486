import React, {useEffect, useRef, useState} from "react";
import './Information.scss'
import NavigationBar from "../../components/navigation-bar/NavigationBar";
import {Link, useHistory} from "react-router-dom";
import Popup from "../../components/popup/Popup";
import ErrorView from "../../components/error-view/ErrorView";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import {InformationRequest} from "./InformationRequest";
import {AppUtils} from "../../utils/AppUtils";

function Information() {
    const history = useHistory();
    const informationRequest = useRef(null);
    const [informationData, setInformationData] = useState(/** @type {InformationData|null} */ null);
    const [requestError, setRequestError] = useState(null);

    useEffect(() => {
        if(!informationData && !requestError) {
            informationRequest.current?.cancel();
            informationRequest.current = new InformationRequest();
            informationRequest.current.onSuccess = (informationData) => {
                setInformationData(informationData);
            }
            informationRequest.current.onFailure = (error) => {
                setRequestError(true);
            }
            informationRequest.current.enqueue();

            return () => {
                //Happens when component is dismounted, so cancel request
                informationRequest.current?.cancel();
            }
        }
    }, [informationData, requestError])

    return (
        <div id="information" className="main">
            <div className="page-content-wrapper">
                <NavigationBar title="Informatie"
                               rightTitle={"Sluiten"}
                               rightIcon={"close"}
                               rightIconClick={() => {
                                   history.goBack();
                               }}
                />
                <PageContent />
            </div>
        </div>
    );

    function PageContent() {
        if(requestError) {
            return <ErrorView
                retryOnClick={() => {
                    setRequestError(null);
                }}
            />;
        } else if(informationData) {
            return <FilledPageContent />;
        } else {
            return <LoadingIndicator
                indicatorColor={"dark"}
                showBackgroundOverlay={false}
                isFullscreen={false}
                isLoading={true}
            />
        }
    }

    function FilledPageContent() {
        const listHtml = [];
        informationData.pages.forEach((item, index) => {
            listHtml.push(
                <ListItem key={"list-item-" + index} title={item.title} id={item.id} />
            )
        })

        return (
            <React.Fragment>
                <div className="page-content-scroller">
                    <div className={"page-content row with-small-padding"}>
                        <div className="top-container">
                            {listHtml}
                        </div>
                        <div className="bottom-container">
                            <div className={"link"} onClick={() => {
                                closeRoute();
                            }}>Route sluiten</div>
                        </div>
                    </div>
                </div>
                { informationData.skylineImageUrl && <div className="footer skyline" style={{backgroundImage: 'url(' + informationData.skylineImageUrl + ')'}} /> }
            </React.Fragment>
        );
    }

    function ListItem(listProps) {
        return <div className={"list-item"} onClick={() => {
            history.push("/information/" + listProps.id);
        }}>{listProps.title}</div>;
    }

    function closeRoute() {
        const config = {
            title: "Pas op!",
            text: "Weet je zeker dat je de route wilt sluiten?",
            confirmButtonText: "Route sluiten",
            cancelButtonText: "Annuleren"
        };
        Popup.show(config, doCloseRoute);
    }

    function doCloseRoute() {
        AppUtils.closeRoute();
    }
}

export default Information;