import React, {createRef} from "react";
import './SolutionsGridView.scss'

function SolutionsGridView(props) {
    /** @var {SolutionsGridObject} */
    const item = props.item;

    const boxesHtml = [];
    const inputRefs = [];
    let hasLetterFocus = false;
    let clickedInView = false;
    let focusRequested = false;
    let valuesChanged = false;

    for(let i = 0; i < item.length; i++) {
        const newRef = createRef();
        let oldValue = item.value?.[i] ?? "";
        boxesHtml.push(
            <div key={i} className={"box"}>
                <div className={"box-inner"}>
                    <input type="text"
                           className={"box-input" + (item.highlightedCharacters.includes(i) ? " highlighted" : "")}
                           defaultValue={oldValue}
                           autoCapitalize={"characters"}
                           disabled={!props.isEnabled || item.isJokerUsed}
                           onChange={(event) => {
                               if(event.currentTarget.value.length > 1) {
                                   event.currentTarget.value = event.currentTarget.value[event.currentTarget.value.length - 1];
                               }
                               if(event.currentTarget.value.length > 0) {
                                   event.currentTarget.value = event.currentTarget.value.toUpperCase();
                               }

                               if(oldValue !== event.currentTarget.value) {
                                   valuesChanged = true;
                               }

                               if(event.currentTarget.value.length > 0) {
                                   if((i + 1) < inputRefs.length) {
                                       focusRequested = true;
                                       inputRefs[i + 1].current.focus();
                                   } else {
                                       inputRefs[i].current.blur();
                                   }
                               }
                           }}
                           ref={newRef}
                           onKeyDown={(event) => {
                               //Backspace pressed on empty box
                               if(event.key === "Backspace" && event.currentTarget.value.length === 0 && (i - 1) >= 0) {
                                   event.preventDefault();
                                   focusRequested = true;
                                   inputRefs[i - 1].current.value = ""
                                   inputRefs[i - 1].current.focus();
                               } else if(event.key === "ArrowLeft" && (i - 1) >= 0) {
                                   event.preventDefault();
                                   focusRequested = true;
                                   inputRefs[i - 1].current.focus();
                               } else if(event.key === "ArrowRight" && (i + 1) < inputRefs.length) {
                                   event.preventDefault();
                                   focusRequested = true;
                                   inputRefs[i + 1].current.focus();
                               }
                           }}
                           onFocus={(event) => {
                               focusRequested = false;
                               hasLetterFocus = true;
                               //Set timeout otherwise chrome won't work
                               setTimeout(() => {
                                   //Set cursor at the end when focus gained
                                   event.target.selectionStart = event.target.selectionEnd = event.target.value.length;
                               }, 0);
                           }}
                           onBlur={() => {
                               hasLetterFocus = false;
                               if(shouldNotifyBlurImmediately()) {
                                   notifyOnBlur();
                               } else {
                                   setTimeout(() => {
                                       if(!hasLetterFocus) {
                                           notifyOnBlur();
                                       }
                                   }, 10);
                               }
                           }}
                    />
                </div>
            </div>
        )
        inputRefs.push(newRef);
    }

    /**
     * @return {boolean}
     */
    function shouldNotifyBlurImmediately() {
        if(!clickedInView && !focusRequested) {
            //If we didn't click inside this view and haven't requested a new focus ourselves we have to execute the blur immediately so a loader can prevent clicks
            return true;
        }
        return false;
    }

    /**
     * @return {Array}
     */
    function getTypedText() {
        let typedText = [];
        for(let y = 0; y < inputRefs.length; y++) {
            typedText.push(inputRefs[y].current.value);
        }
        return typedText;
    }

    function notifyOnBlur() {
        if(valuesChanged) {
            props.onBlur(getTypedText());
        }
    }

    return (
        <div
            className={"solutions-grid-view" + (!props.isEnabled ? " disabled" : "")}
            onMouseDown={() =>{
                clickedInView = true;
                setTimeout(() => {
                    clickedInView = false;
                }, 10)
            }}
        >
            <div className={"label-container"}>
                <div className={"label"}>{item.label}</div>
            </div>
            <div className={"boxes-container" + ((item.isJokerUsed) ? " joker-used" : "")}>
                {boxesHtml}
            </div>
        </div>
    )
}

export default SolutionsGridView;