import JsonError from "./JsonError";
import JsonObject from "./JsonObject";
import JsonUtils from "./JsonUtils";

class JsonArray {
    constructor(json) {
        if(!JsonArray.isJsonArray(json)) {
            throw new JsonError("This is not a JsonArray");
        }

        this.jsonData = json;
    }

    /**
     * @typedef forEachIndexedCallback
     * @function
     * @param {int} index
     */

    /**
     * @param {function(int)} callFunction
     */
    forEachIndexed(callFunction) {
        for(let i = 0; i < this.jsonData.length; i++) {
            callFunction(i);
        }
    }

    get(index) {
        return this.jsonData[index];
    }

    getObj(index) {
        return new JsonObject(this.jsonData[index]);
    }

    getInt(index) {
        return JsonUtils.int(this.jsonData[index], "JsonArray index " + index)
    }

    getString(index) {
        return JsonUtils.string(this.jsonData[index], "JsonArray index " + index)
    }

    /**
     * @param json
     * @returns {boolean}
     */
    static isJsonArray(json) {
        return Array.isArray(json);
    }
}

export default JsonArray;