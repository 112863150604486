/**
 * @property {int} id
 * @property {string} title
 * @property {string} subtitle
 * @property {string} imageUrl
 * @property {boolean} crossed
 * @property {boolean} isCrossedCurrentStep
 * @property {boolean} greyedOut
 * @property {boolean} jokerUsed
 */
class GalleryItem {
    constructor() {
        this.id = 0;
        this.title = "";
        this.subtitle = "";
        this.imageUrl = "";
        this.crossed = false;
        this.isCrossedCurrentStep = false;
        this.greyedOut = false;
        this.jokerUsed = false;
    }

    /**
     * @param {int} id
     * @param {string} title
     * @param {string} subtitle
     * @param {string} imageUrl
     * @param {boolean} crossed
     * @param {boolean} jokerUsed
     * @return {GalleryItem}
     */
    static create(id, title, subtitle = "", imageUrl, crossed, jokerUsed = false) {
        let galleryItem = new GalleryItem();
        galleryItem.id = id;
        galleryItem.title = title;
        galleryItem.subtitle = subtitle;
        galleryItem.imageUrl = imageUrl;
        galleryItem.crossed = crossed;
        galleryItem.jokerUsed = jokerUsed;
        return galleryItem;
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.id = jsonObject.int("id");
        this.title = jsonObject.stringDefault("title", "");
        this.subtitle = jsonObject.stringNull("subtitle");
        this.imageUrl = jsonObject.stringDefault("image", "");
        this.crossed = jsonObject.booleanDefault("isCrossed", false);
        this.isCrossedCurrentStep = jsonObject.booleanDefault("isCrossedCurrentStep", false);
        this.jokerUsed = jsonObject.booleanDefault("isJokerUsed", false);
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {GalleryItem}
     */
    static parse(jsonObject) {
        const galleryItem = new GalleryItem();
        galleryItem.parse(jsonObject);
        return galleryItem;
    }
}

export default GalleryItem;