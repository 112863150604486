import MapItem from "./MapItem";
import GalleryItem from "../../models/GalleryItem";
import MapData from "./MapData";
import GalleryData from "../../models/GalleryData";
import JsonObject from "../../utils/json/JsonObject";
import JsonError from "../../utils/json/JsonError";
import CombiboxObject from "../../models/CombiboxObject";

/**
 * @property {string} skylineImageUrl
 * @property {Array.<OverviewTab>} tabs
 */
class OverviewData {
    constructor() {
        this.skylineImageUrl = ""
        this.tabs = []
    }

    changeCombibox(newCombiboxObject) {
        for(/** @type {OverviewTab} */ const tab in this.tabs) {
            if(tab.type === "combibox") {
                tab.tabContent = newCombiboxObject;
                break;
            }
        }
    }

    /**
     * @return {OverviewData}
     */
    static fillTestData() {
        let overviewData = new OverviewData();
        overviewData.skylineImageUrl = require('../../resources/add_to_home_screen_footer.png').default
        overviewData.tabs.push(OverviewTab.create("Map", "map", this.mapTestData()))
        overviewData.tabs.push(OverviewTab.create("Verdachten", "gallery", this.suspectGalleryTestData()))
        overviewData.tabs.push(OverviewTab.create("Wapens", "gallery", this.weaponsGalleryTestData()))
        return overviewData
    }

    static mapTestData() {
        return MapData.create(1, [
            MapItem.create(1, "normal", "Moordzaak"),
            MapItem.create(2, "normal", "Start"),
            MapItem.create(3, "clue", "Clue 1", require('../../resources/test/test_suspect_luna_vossen.png').default),
            MapItem.create(4, "clue", "Clue 2"),
            MapItem.create(5, "normal", "Wegwijzer"),
            MapItem.create(6, "fact", "Feitje"),
            MapItem.create(7, "secret", "Geheime info"),
            MapItem.create(8, "normal", "Wegwijzer"),
            MapItem.create(9, "clue", "Clue 3"),
            MapItem.create(10, "clue", "Clue 4"),
            MapItem.create(11, "clue", "Clue 4"),
        ])
    }

    static suspectGalleryTestData() {
        const testImage = require('../../resources/test/test_suspect_luna_vossen.png').default
        return GalleryData.create("", null, true, [
            GalleryItem.create(1, "Luna Vossen", "", testImage, true),
            GalleryItem.create(2, "Karel Kogels", "", testImage, false),
            GalleryItem.create(3, "E. Clips", "", testImage, true),
            GalleryItem.create(4, "Saol Simon", "", testImage, false),
            GalleryItem.create(5, "D. Unham", "", testImage, true, true),
            GalleryItem.create(6, "Jacky G. Sult", "", testImage, false),
            GalleryItem.create(7, "Erik Zaaduim", "", testImage, false),
            GalleryItem.create(8, "V.B. Vuilbak", "", testImage, true),
        ])
    }

    static weaponsGalleryTestData() {
        const testImage = require('../../resources/test/test_suspect_luna_vossen.png').default
        return GalleryData.create("", null, true, [
            GalleryItem.create(1, "1617", "Vergif", testImage, false),
            GalleryItem.create(2, "1680", "Touw", testImage, false),
            GalleryItem.create(3, "1740", "Pen", testImage, true),
            GalleryItem.create(4, "1807", "Schaar", testImage, false),
            GalleryItem.create(5, "1604", "Glas", testImage, true),
        ])
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.skylineImageUrl = jsonObject.stringNull("skylineImageUrl");
        this.tabs = [];
        const tabsJsonArray = jsonObject.array("tabs");
        tabsJsonArray.jsonData.forEach((itemJsonData) => {
            const tabJsonObject = new JsonObject(itemJsonData);
            const tab = OverviewTab.parse(tabJsonObject);
            this.tabs.push(tab);
        });
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {OverviewData}
     */
    static parse(jsonObject) {
        const overviewData = new OverviewData();
        overviewData.parse(jsonObject);
        return overviewData;
    }
}

/**
 * @property {string} title
 * @property {string} type
 * @property {Object} tabContent
 */
export class OverviewTab {
    constructor() {
        this.title = ""
        this.type = ""
        this.tabContent = []
    }

    /**
     * @param {string} title
     * @param {string} type
     * @param {Object} tabContent
     * @return {OverviewTab}
     */
    static create(title, type, tabContent) {
        let overviewTab = new OverviewTab();
        overviewTab.title = title
        overviewTab.type = type
        overviewTab.tabContent = tabContent
        return overviewTab
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.title = jsonObject.stringDefault("title", "");
        this.type = jsonObject.string("type");

        if(this.type === "map") {
            this.tabContent = MapData.parseFromTab(jsonObject);
        } else if(this.type === "gallery") {
            this.tabContent = GalleryData.parse(jsonObject);
        } else if(this.type === "combibox") {
            this.tabContent = CombiboxObject.parse(jsonObject);
        } else {
            throw new JsonError("Tab type not known");
        }
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {OverviewTab}
     */
    static parse(jsonObject) {
        const overviewTab = new OverviewTab();
        overviewTab.parse(jsonObject);
        return overviewTab;
    }
}

export default OverviewData;