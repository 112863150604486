import React from "react";
import './DeactivationCodeResultView.scss'
import LetterBoxView from "../letter-box/LetterBoxView";

function DeactivationCodeResultView(props) {
    /** @var {String} */
    const value = props.value;

    const valueHtml = [];
    for(let i = 0; i < value.length; i++) {
        valueHtml.push(
            <div key={"box-wrapper-" + i} className={"box-wrapper"}>
                <LetterBoxView value={value[i]} />
            </div>
        )
    }

    return (
        <div className={"deactivation-code-result-view"}>
            {valueHtml}
        </div>
    )
}

export default DeactivationCodeResultView;