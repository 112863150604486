import {BaseRequest} from "../../api/BaseRequest";
import JsonObject from "../../utils/json/JsonObject";
import EndObject from "./EndObject";

/**
 * @property {function|null} onSuccess
 * @property {function|null} onFailure
 */
export class EndRequest extends BaseRequest {
    constructor() {
        super();

        this.onSuccess = null;
        this.onFailure = null;
    }

    createRequest() {
        return {
            method: 'get',
            url: '/endroute',
            // url: 'https://zooma.mockable.io/speurroutes/end',
            headers: this.appUserAccessTokenHeaders()
        }
    }

    handleSuccess(response) {
        try {
            const jsonObject = new JsonObject(response.data);
            const endObject = EndObject.parse(jsonObject);
            this.onSuccess?.(endObject);
        } catch(error) {
            this.handleFailure(error);
        }
    }

    handleFailure(error) {
        this.onFailure?.(error);
    }
}