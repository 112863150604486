import AppStorage from "./AppStorage";
import {Redirect, Route} from "react-router-dom";
import Home from "../app/home/Home";
import Overview from "../app/overview/Overview";
import Step from "../app/step/Step";
import Notes from "../app/notes/Notes";
import InformationDetail from "../app/information/detail/InformationDetail";
import Information from "../app/information/Information";
import CodeAlphabet from "../app/code-alphabet/CodeAlphabet";
import SolutionPage from "../app/solution/SolutionPage";
import FeedbackPage from "../app/feedback/FeedbackPage";
import EndPage from "../app/end/EndPage";
import Redeem from "../app/redeem/Redeem";
import React from "react";
import AddToHomeScreen from "../app/add-to-home-screen/AddToHomeScreen";
import {history} from "./AppHistory";

/**
 * @property {Array} routes
 * @property {Function|null} updateRoutesSwitch
 */
export class AppUtils {
    static setShouldUpdateAppGuid = null;
    static routes = [];
    static updateRoutesSwitch = null;

    static updateRoutes() {
        this.routes = [];
        //Filled in code
        if(AppStorage.accessToken().length > 0) {
            this.routes.push(<Route key={"home"} path="/home" exact component={Home} />);
            this.routes.push(<Route key={"overview"} path="/overview" exact component={Overview} />);
            this.routes.push(<Route key={"step"} path="/step/:id" exact component={Step} />);
            this.routes.push(<Route key={"notes"} path="/notes" exact component={Notes} />);
            this.routes.push(<Route key={"information-with-id"} path="/information/:id" exact component={InformationDetail} />);
            this.routes.push(<Route key={"information"} path="/information" exact component={Information} />);
            this.routes.push(<Route key={"code-alphabet"} path="/code-alphabet/:id" exact component={CodeAlphabet} />);

            if(AppStorage.finishedRoute()) {
                this.routes.push(<Route key={"solution"} path="/solution" exact component={SolutionPage} />);
                this.routes.push(<Route key={"feedback"} path="/feedback" exact component={FeedbackPage} />);
                this.routes.push(<Route key={"end"} path="/end" exact component={EndPage} />);
            }
            this.routes.push(<Redirect key={"redirect-home"} to={'/home'} />);
        } else {
            this.routes.push(<Route key={"redeem"} path="/redeem" exact component={Redeem} />);
            this.routes.push(<Route key={"add-to-homescreen"} path="/" exact component={AddToHomeScreen} />);
            this.routes.push(<Redirect key={"redirect-default-page"} to={'/'} />);
        }
        this.updateRoutesSwitch?.()
    }

    static closeRoute() {
        const appGuidShouldUpdate = AppStorage.appGuidShouldUpdate();

        AppStorage.removeAllRouteData();

        if(appGuidShouldUpdate) {
            this.setShouldUpdateAppGuid?.(true)
        } else {
            this.updateRoutes();
            history.push('/redeem')
        }
    }
}