/**
 * @property {int} id
 * @property {string} title
 * @property {string} value
 */
class NoteItem {
    constructor() {
        this.id = 0;
        this.title = "";
        this.value = "";
    }

    /**
     * @param {int} id
     * @param {string} title
     * @param {string} value
     * @return {NoteItem}
     */
    static create(id, title, value = "") {
        let noteItem = new NoteItem();
        noteItem.id = id;
        noteItem.title = title;
        noteItem.value = value;
        return noteItem;
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.id = jsonObject.int("id");
        this.title = jsonObject.stringDefault("title", "");
        this.value = jsonObject.stringDefault("value", "");
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {NoteItem}
     */
    static parse(jsonObject) {
        const noteItem = new NoteItem();
        noteItem.parse(jsonObject);
        return noteItem;
    }
}

export default NoteItem;