import JsonError from "./JsonError";

class JsonUtils {
    /**
     * @param {any|null} jsonValue
     * @param {string} errorName
     * @returns {int}
     * @throws
     */
    static int(jsonValue, errorName = "Json value") {
        if(!this.isNullOrUndefined(jsonValue)) {
            if(typeof jsonValue === 'string') {
                jsonValue = Number(jsonValue);
            }
            if(typeof jsonValue === 'number' && !isNaN(jsonValue)) {
                return Math.trunc(jsonValue);
            }
        }
        throw new JsonError(errorName + " is not an int");
    }

    /**
     * @param {any|null} jsonValue
     * @param {string} errorName
     * @returns {string}
     * @throws
     */
    static string(jsonValue, errorName = "Json value") {
        if(this.isNullOrUndefined(jsonValue)) {
            throw new JsonError(errorName + " is not a string");
        }
        if(typeof jsonValue === 'string') {
            return jsonValue;
        }
        return "" + jsonValue;
    }

    /**
     * @param value
     * @return {boolean}
     */
    static isNullOrUndefined(value) {
        return value === null || typeof value === 'undefined';
    }
}

export default JsonUtils;