import Swal, {DismissReason} from "sweetalert2";
import './Popup.scss'

class Popup {
    static show(config, confirm = null, cancel = null) {
        const finalConfig = {
            title: config.title ?? "",
            text: config.text ?? "",
            confirmButtonText: config.confirmButtonText ?? "",
            cancelButtonText: config.cancelButtonText ?? ""
        };

        const showCancelButton = finalConfig.cancelButtonText.length > 0;
        const fullWidthButtonClass = showCancelButton ? "" : " full-width-button"

        const swalOptions = {
            title: finalConfig.title,
            html: finalConfig.text,
            showCancelButton: showCancelButton,
            confirmButtonText: finalConfig.confirmButtonText,
            cancelButtonText: finalConfig.cancelButtonText,
            buttonsStyling: false,
            heightAuto: false,
            customClass: {
                image: "custom-image",
                popup: "custom-popup",
                container: "custom-container",
                content: "custom-content",
                header: "custom-header",
                actions: "custom-actions" + fullWidthButtonClass,
                confirmButton: "custom-confirm-button",
                cancelButton: "custom-cancel-button quaternary"
            }
        }

        if(config.cancelOutside === false) {
            swalOptions.allowOutsideClick = false;
            swalOptions.allowEscapeKey = false;
        }

        if(config.imageUrl) {
            swalOptions.imageUrl = config.imageUrl;
        }

        Swal.fire(swalOptions)
            .then(function(result) {
                if(result.isConfirmed) {
                    if(confirm !== null) {
                        confirm();
                    }
                } else {
                    if(cancel !== null) {
                        cancel();
                    }
                }
        });
    }

    static showDefaultRequestError(confirm, cancel = null) {
        Popup.show({
                title: "Fout",
                text: "Er is een fout opgetreden. Controleer je internetverbinding en probeer het opnieuw.",
                confirmButtonText: "Opnieuw",
                cancelButtonText: "Annuleren"
            },
            confirm,
            cancel
        )
    }
}

export default Popup;