import React, {useEffect, useRef, useState} from "react";
import './InformationDetail.scss'
import NavigationBar from "../../../components/navigation-bar/NavigationBar";
import {useHistory} from "react-router-dom";
import {InformationDetailRequest} from "./InformationDetailRequest";
import ErrorView from "../../../components/error-view/ErrorView";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";

function InformationDetail(props) {
    const history = useHistory();
    const pageId = props.match.params.id;
    const informationDetailRequest = useRef(null);
    const [informationPage, setInformationPage] = useState(/** @type {InformationPage|null} */ null);
    const [requestError, setRequestError] = useState(null);

    useEffect(() => {
        if(informationPage) {
            setInformationPage(null);
        }
    }, [pageId]);

    useEffect(() => {
        if(!informationPage && !requestError) {
            informationDetailRequest.current?.cancel();
            informationDetailRequest.current = new InformationDetailRequest(pageId);
            informationDetailRequest.current.onSuccess = (informationPage) => {
                setInformationPage(informationPage);
            }
            informationDetailRequest.current.onFailure = (error) => {
                setRequestError(true);
            }
            informationDetailRequest.current.enqueue();

            return () => {
                //Happens when component is dismounted, so cancel request
                informationDetailRequest.current?.cancel();
            }
        }
    }, [informationPage, requestError])

    return (
        <div id="information-detail" className="main">
            <div className="page-content-wrapper">
                <NavigationBar title={informationPage?.title ?? ""}
                               transparent={false}
                               leftTitle={"Terug"}
                               leftIcon={"back"}
                               leftIconClick={() => {
                                   history.goBack();
                               }}
                />
                <PageContent />
            </div>
        </div>
    );

    function PageContent() {
        if(requestError) {
            return <ErrorView
                retryOnClick={() => {
                    setRequestError(null);
                }}
            />;
        } else if(informationPage) {
            return <FilledPageContent />;
        } else {
            return <LoadingIndicator
                indicatorColor={"dark"}
                showBackgroundOverlay={false}
                isFullscreen={false}
                isLoading={true}
            />
        }
    }

    function FilledPageContent() {
        return (
            <div className="page-content">
                { informationPage.imageUrl && <div className="cover-image" style={{backgroundImage: 'url(' + informationPage.imageUrl + ')'}} /> }
                <div className={"content-wrapper row with-small-padding" + ((informationPage.imageUrl) ? " with-image" : " no-image")}>
                    <div className="card">
                        <div className="text" dangerouslySetInnerHTML={{__html: informationPage.text}} />
                    </div>
                </div>
            </div>
        )
    }
}

export default InformationDetail;