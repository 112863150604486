import {v4 as uuidv4} from 'uuid';

class AppStorage {
    /** @return {boolean} */
    static shouldUpdateAppGuid() {
        if(!this.appGuid() || this.appGuidShouldUpdate()) {
            return true;
        }
        return false;
    }

    /** @return {string} */
    static appGuid() {
        return localStorage.getItem("appGuid") ?? "";
    }

    /** @param {string} appGuid */
    static setAppGuid(appGuid) {
        localStorage.setItem("appGuid", appGuid);
    }

    /**
     * @return {string}
     */
    static getOrCreateAppGuid() {
        let appGuid = this.appGuid();
        if(!appGuid) {
            appGuid = this.generateAppGuid();
        }
        return appGuid;
    }

    /** @return {boolean} */
    static appGuidShouldUpdate() {
        return (localStorage.getItem("appGuidShouldUpdate") ?? "") === 'true';
    }

    /** @param {boolean} shouldUpdate */
    static setAppGuidShouldUpdate(shouldUpdate) {
        localStorage.setItem("appGuidShouldUpdate", ((shouldUpdate) ? 'true' : 'false'));
    }

    /** @return {string} */
    static generateAppGuid() {
        return uuidv4();
    }

    /**
     * @return {string}
     */
    static accessToken() {
        return localStorage.getItem("accessToken") ?? "";
    }

    /**
     * @param {string} accessToken
     */
    static setAccessToken(accessToken) {
        localStorage.setItem("accessToken", accessToken);
    }

    static removeAccessToken() {
        localStorage.removeItem("accessToken");
    }

    /**
     * @return {string}
     */
    static routeType() {
        return localStorage.getItem("routeType") ?? "";
    }

    /**
     * @param {string} routeType
     */
    static setRouteType(routeType) {
        localStorage.setItem("routeType", routeType);
    }

    static removeRouteType() {
        localStorage.removeItem("routeType");
    }

    /**
     * @return {boolean}
     */
    static addToHomeScreenMaybeLater() {
        return (localStorage.getItem("addToHomeScreenMaybeLater") ?? "") === 'true';
    }

    /**
     * @param {boolean} addToHomeScreenMaybeLater
     */
    static setAddToHomeScreenMaybeLater(addToHomeScreenMaybeLater) {
        localStorage.setItem("addToHomeScreenMaybeLater", ((addToHomeScreenMaybeLater) ? 'true' : 'false'));
    }

    /**
     * @return {boolean}
     */
    static browserNotSupportedContinueAnyway() {
        return (localStorage.getItem("browserNotSupportedContinueAnyway") ?? "") === 'true';
    }

    /**
     * @param {boolean} browserNotSupportedContinueAnyway
     */
    static setBrowserNotSupportedContinueAnyway(browserNotSupportedContinueAnyway) {
        localStorage.setItem("browserNotSupportedContinueAnyway", ((browserNotSupportedContinueAnyway) ? 'true' : 'false'));
    }

    /**
     * @return {boolean}
     */
    static finishedRoute() {
        return (localStorage.getItem("finishedRoute") ?? "") === 'true';
    }

    /**
     * @param {boolean} finishedRoute
     */
    static setFinishedRoute(finishedRoute) {
        localStorage.setItem("finishedRoute", ((finishedRoute) ? 'true' : 'false'));
    }

    static removeFinishedRoute() {
        localStorage.removeItem("finishedRoute");
    }

    /**
     * @return {Array}
     */
    static secretInfoShownIds() {
        return JSON.parse(localStorage.getItem("secretInfoShownIds") ?? "[]");
    }

    /**
     * @param {int} secretInfoShownId
     */
    static addSecretInfoShownId(secretInfoShownId) {
        let ids = this.secretInfoShownIds();
        ids.push(secretInfoShownId);
        this.setSecretInfoShownIds(ids);
    }

    /**
     * @param {Array} secretInfoShownIds
     */
    static setSecretInfoShownIds(secretInfoShownIds) {
        localStorage.setItem("secretInfoShownIds", JSON.stringify(secretInfoShownIds));
    }

    static removeSecretInfoShownIds() {
        localStorage.removeItem("secretInfoShownIds");
    }

    static removeAllRouteData() {
        this.removeRouteType();
        this.removeAccessToken();
        this.removeFinishedRoute();
        this.removeSecretInfoShownIds();
    }

//     let isAppGUIDRegistered = function() {
//         let appGUID = getRegisteredAppGUID();
//         return !!appGUID && appGUID !== 'null';
//     };
//
//     let getRegisteredAppGUID = function () {
//         return localStorage.getItem('registeredAppGUID');
//     };
//
//     let setRegisteredAppGUID = function (appGUID) {
//         if(appGUID) {
//             localStorage.setItem('registeredAppGUID', appGUID);
//         } else {
//             localStorage.removeItem('registeredAppGUID');
//         }
//     };
//
//     let generateAppGUID = function () {
//         return uuidv4();
//     };
}

export default AppStorage;