import {BaseRequest} from "../../api/BaseRequest";

/**
 * @property {Object} jsonData
 * @property {function|null} onSuccess
 * @property {function|null} onFailure
 */
export class FeedbackPostRequest extends BaseRequest {
    /**
     * @param {Object} jsonData
     */
    constructor(jsonData) {
        super();

        this.jsonData = jsonData;
        this.onSuccess = null;
        this.onFailure = null;
    }

    createRequest() {
        return {
            method: 'post',
            url: '/feedback',
            // url: 'https://zooma.mockable.io/speurroutes/feedback',
            headers: this.appUserAccessTokenHeaders(),
            data: this.jsonData
        }
    }

    handleSuccess(response) {
        this.onSuccess?.();
    }

    handleFailure(error) {
        this.onFailure?.(error);
    }
}