import React from "react";
import './BrowserNotSupportedScreen.scss'
import AppStorage from "../../utils/AppStorage";

function BrowserNotSupportedScreen(props) {
    let recommendedBrowser = "Chrome of Firefox"
    if(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
        recommendedBrowser = "Safari"
    } else if(/Android/i.test(navigator.userAgent)) {
        recommendedBrowser = "Chrome"
    }

    return (
        <div id="browsernotsupported" className="main">
            <div className={"page-content row with-padding"}>
                <h1>Browser niet ondersteund</h1>
                <p>Deze browser wordt niet ondersteund. Wij raden aan om {recommendedBrowser} te gebruiken. Het is mogelijk om de Speurroute te lopen via je huidige browser, maar een andere browser zal een betere beleving bieden.</p>
                <div className={"continue-anyway-link"} onClick={continueAnywayClicked}>Ik wil verder met deze browser</div>
            </div>
            <div className="footer skyline" />
        </div>
    );

    function continueAnywayClicked() {
        AppStorage.setBrowserNotSupportedContinueAnyway(true);
        props.continueAnywayClicked();
    }
}

export default BrowserNotSupportedScreen;