import GalleryItem from "./GalleryItem";
import SolutionsGridObject from "./SolutionsGridObject";
import DeactivationCodeObject, {DeactivationCodeColumn} from "./DeactivationCodeObject";
import GalleryData from "./GalleryData";

/**
 * @property {int} id
 * @property {string} title
 * @property {string} type
 * @property {boolean} isRouteFinished
 * @property {string|null} imageUrl
 * @property {string} text
 * @property {int|null} previousStepId
 * @property {int|null} nextStepId
 * @property {boolean|null} jokerAvailable
 * @property {int|null} codeAlphabetId
 * @property {string|null} codeAlphabetButtonTitle
 * @property {GalleryData|null} gallery
 * @property {SolutionsGridObject|null} solutionsGrid
 * @property {DeactivationCodeObject|null} deactivationCode
 */
class StepObject {
    constructor() {
        this.id = 0;
        this.title = "";
        this.type = "";
        this.isRouteFinished = false;
        this.imageUrl = "";
        this.text = "";
        this.previousStepId = 0;
        this.nextStepId = 0;
        this.jokerAvailable = null;
        this.codeAlphabetId = null;
        this.codeAlphabetButtonTitle = null;
        this.gallery = null;
        this.solutionsGrid = null;
        this.deactivationCode = null;
    }

    /**
     * @param {string} id
     * @return {StepObject}
     */
    static fillTestData(id) {
        let step = new StepObject();
        if(id === "1") {
            step.id = 1;
            step.title = "De Moordzaak";
            step.type = "normal";
            step.imageUrl = null;
            step.text = "<p>Pyxis Dorado, invloedrijke astronoom en plaatselijke held, had een belangrijke persconferentie aangekondigd. Wereldschokkende onthullingen zou hij daar doen, had hij beloofd. Maar een dag voor de conferentie werd zijn ontzielde lichaam gevonden in zijn woning.</p>" +
                "<p>Het heeft er alle schijn van dat Dorado door een misdrijf om het leven is gekomen. Mogelijk heeft deze tragedie iets te maken met de heftige discussie die Dorado enkele dagen eerder voerde over zwarte gaten en bewoonbare dwergplaneten. Of speelden er andere duistere zaken in het leven van Dorado?</p>" +
                "<p>Kortom: deze vreselijke moord is nog in nevelen gehuld en in verband met de complexiteit van de zaak vraagt de politie jouw hulp. Kun jij erachter komen wie Dorado van het leven heeft beroofd? En wat denk jij dat het motief van de moord zou kunnen zijn? ...</p>"
            step.previousStepId = null;
            step.nextStepId = 2;
            step.jokerAvailable = null;
            step.codeAlphabetButtonTitle = "Morse-alfabet bekijken";
            step.codeAlphabetId = 12;

            const testImage = require('../resources/test/test_suspect_luna_vossen.png').default
            let galleryData = GalleryData.create("Verdachtengalerij", 1, true, [
                GalleryItem.create(1, "Luna Vossen", "", testImage, true),
                GalleryItem.create(2, "Karel Kogels", "", testImage, false),
                GalleryItem.create(3, "E. Clips", "", testImage, true),
                GalleryItem.create(4, "Saol Simon", "", testImage, false),
                GalleryItem.create(5, "D. Unham", "", testImage, true, true),
                GalleryItem.create(6, "Jacky G. Sult", "", testImage, false),
                GalleryItem.create(7, "Erik Zaaduim", "", testImage, false),
                GalleryItem.create(8, "V.B. Vuilbak", "", testImage, false),
            ])
            galleryData.setGreyedOutExceptSelectedItem();
            // galleryData.isEnabled = false
            step.gallery = galleryData
        } else if(id === "2") {
            step.id = 2;
            step.title = "Clue 1";
            step.type = "normal";
            step.imageUrl = null;
            step.text = "<p>Ga met je neus naar het bordes staan. Wat houdt het stenen figuur links van de deur bovenaan het bordes een tikje verborgen achter zijn rug? Schrijf het antwoord hieronder. Streep daarna de tekens in de witte vakjes weg in de combibox.</p>"
            step.previousStepId = 1;
            step.nextStepId = 3;
            step.jokerAvailable = true;

            step.solutionsGrid = new SolutionsGridObject();
            step.solutionsGrid.id = 124;
            step.solutionsGrid.title = "Oplossingenraster";
            step.solutionsGrid.label = "21";
            step.solutionsGrid.value = null;
            step.solutionsGrid.length = 9;
            step.solutionsGrid.highlightedCharacters = [0, 3, 7];
        } else if(id === "3") {
            step.id = 3;
            step.title = "Clue 2";
            step.type = "normal";
            step.imageUrl = "https://i.ytimg.com/vi/7GVGQVyl8x0/maxresdefault.jpg";
            step.text = "<p>Yo, snuit. Het is tijd voor onze krachttraining.</p>"
            step.previousStepId = 2;
            step.nextStepId = 4;
            step.jokerAvailable = null;

            step.deactivationCode = new DeactivationCodeObject();
            step.deactivationCode.id = 111;
            step.deactivationCode.title = "Deactivatiecode";
            step.deactivationCode.value = null;
            step.deactivationCode.errorMessage = "Als je 5 tekens in de combibox overhoudt wordt de deactivatiecode automatisch ingevuld.";

            const column = new DeactivationCodeColumn();
            column.characters = "A3O19V";
            column.crossedCharacters = [1, 3, 5];

            const column2 = new DeactivationCodeColumn();
            column2.characters = "XEMH8C";
            column2.crossedCharacters = [0, 1, 3, 4, 5];

            step.deactivationCode.columns = [column, column2];
        }

        return step;
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.id = jsonObject.int("id");
        this.title = jsonObject.stringDefault("title", "");
        this.type = jsonObject.string("type");
        this.isRouteFinished = jsonObject.boolean("isRouteFinished");
        this.imageUrl = jsonObject.stringDefault("imageUrl", "");
        this.previousStepId = jsonObject.intDefault("previousStepId", 0);
        this.nextStepId = jsonObject.intDefault("nextStepId", 0);
        this.jokerAvailable = jsonObject.booleanNull("jokerAvailable");
        this.text = jsonObject.stringDefault("text", "");

        const codeAlphabetJson = jsonObject.objNull("codeAlphabet");
        if(codeAlphabetJson) {
            this.codeAlphabetId = codeAlphabetJson.int("id")
            this.codeAlphabetButtonTitle = codeAlphabetJson.string("buttonTitle")
        }

        const galleryJson = jsonObject.objNull("gallery");
        if(galleryJson) {
            this.gallery = GalleryData.parse(galleryJson);
            this.gallery.setGreyedOutExceptSelectedItem();
        }

        const solutionsGridJson = jsonObject.objNull("solutionsGrid");
        if(solutionsGridJson) {
            this.solutionsGrid = SolutionsGridObject.parse(solutionsGridJson);
        }

        const deactivationCodeJson = jsonObject.objNull("deactivationCode");
        if(deactivationCodeJson) {
            this.deactivationCode = DeactivationCodeObject.parse(deactivationCodeJson);
        }
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {StepObject}
     */
    static parse(jsonObject) {
        const stepObject = new StepObject();
        stepObject.parse(jsonObject);
        return stepObject;
    }
}

export default StepObject;