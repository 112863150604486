import ClipLoader from "react-spinners/ClipLoader";
import React from "react";
import "./LoadingIndicator.scss"
import ValidationHelper from "../../utils/ValidationHelper";

/**
 * Possible 'props' property values
 * @property isLoading {boolean}  - True is default
 * @property indicatorColor {string} - Dark is default
 * @property showBackgroundOverlay {boolean} - False is default
 * @property isFullscreen {boolean} - False is default
 * Example usage: <LoadingIndicator isLoading={true} indicatorColor={"light"} showBackgroundOverlay={true} isFullscreen={false}/>
 */

function LoadingIndicator(props) {
    const loaderLightColor = "white";
    const loaderDarkColor = "#444444";

    const isLoading = ValidationHelper.exists(props.isLoading) ? props.isLoading : true; //isLoading defaults to 'true'
    const indicatorColor = props.indicatorColor === 'light' ? loaderLightColor : loaderDarkColor; //Default loading color is 'DarkColor'
    const backgroundOverlayColor = props.showBackgroundOverlay === true ? "rgba(0,0,0,0.5)" : "none"; //Default background is transparent
    const isFullscreen = ValidationHelper.exists(props.isFullscreen) ? props.isFullscreen : false; //Default is false

    const style = {
        backgroundColor: backgroundOverlayColor,
    };

    if(isFullscreen) {
        setFullscreenLoadingIndicator();
    }

    if(!isLoading) {
        return null;
    }

    return (
        <div className="loading-indicator" style={style}>
            <ClipLoader
                size={35}
                color={indicatorColor}
                loading={true}
            />
        </div>
    );

    function setFullscreenLoadingIndicator() {
        style.position = "absolute";

        if(props.showBackgroundOverlay) {
            style.top = 0;
            style.height = "100%";
            style.width = "100%";
            style.zIndex = 999;
        } else {
            style.transform = "translateY(-50%)";
            style.top = "50%";
            style.left = "0px"
            style.width = "100%";
        }
    }
}

export default LoadingIndicator