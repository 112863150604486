import React, {useEffect, useRef, useState} from "react";
import './Notes.scss'
import NavigationBar from "../../components/navigation-bar/NavigationBar";
import {useHistory} from "react-router-dom";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import ErrorView from "../../components/error-view/ErrorView";
import {NotesRequest} from "./NotesRequest";
import {NotesSaveRequest} from "./NotesSaveRequest";
import Popup from "../../components/popup/Popup";

function Notes() {
    const history = useHistory();
    const notesRequest = useRef(null);
    const notesSaveRequest = useRef(/** @type {NotesSaveRequest|null} */ null);
    const notesChanged = useRef(/** @type {Boolean} */ false);
    const [notesData, setNotesData] = useState(/** @type {NotesData|null} */null);
    const [requestError, setRequestError] = useState(null);
    let setLoadingFunc = null;

    useEffect(() => {
        return () => {
            //Happens when component is dismounted, so cancel request
            notesSaveRequest.current?.cancel();
            notesSaveRequest.current = null;
        }
    }, [])

    useEffect(() => {
        if(!notesData && !requestError) {
            notesRequest.current?.cancel();
            notesRequest.current = new NotesRequest();
            notesRequest.current.onSuccess = (notesData) => {
                notesRequest.current = null;
                setNotesData(notesData);
            }
            notesRequest.current.onFailure = (error) => {
                notesRequest.current = null;
                setRequestError(true);
            }
            notesRequest.current.enqueue();

            return () => {
                //Happens when component is dismounted, so cancel request
                notesRequest.current?.cancel();
                notesRequest.current = null;
            }
        }
    }, [notesData, requestError])

    return (
        <div id="notes" className="main">
            <div className="page-content-wrapper">
                <NavigationBar title="Notities"
                               rightTitle={"Sluiten"}
                               rightIcon={"close"}
                               rightIconClick={() => {
                                   finalSaveNotesOrClose();
                               }}
                />
                <PageContent />
            </div>
            <Loader />
        </div>
    );

    function PageContent() {
        if(requestError) {
            return <ErrorView
                retryOnClick={() => {
                    setRequestError(null);
                }}
            />;
        } else if(notesData) {
            return <FilledPageContent />;
        } else {
            return <LoadingIndicator
                indicatorColor={"dark"}
                showBackgroundOverlay={false}
                isFullscreen={false}
                isLoading={true}
            />
        }
    }

    function FilledPageContent() {
        const notesHtml = [];
        for(let i = 0; i < notesData.items.length; i++) {
            /** @var {NoteItem} */
            const item = notesData.items[i];
            notesHtml.push(<Note key={"note-" + i} item={item} shouldFocus={notesData.currentItemId === item.id} />);
        }

        return (
            <div className="page-content">
                <div id={"notes-list"} className={"row with-padding"}>
                    {notesHtml}
                </div>
            </div>
        )
    }

    function Note(noteProps) {
        /** @var {NoteItem} */
        const item = noteProps.item;
        let prevValue = item.value;
        const inputRef = useRef(null);

        useEffect(() => {
            if(inputRef.current) {
                //When loaded, update height
                updateTextAreaHeight(inputRef.current);
            }
        }, []);

        useEffect(() => {
            if(inputRef.current && noteProps.shouldFocus) {
                inputRef.current.focus();
                setTimeout(() => {
                    // select all the content in the element
                    document.execCommand('selectAll', false, null);
                    // collapse selection to the end
                    document.getSelection().collapseToEnd();
                }, 0);
            }
        }, [inputRef, noteProps.shouldFocus]);

        function updateTextAreaHeight(textArea) {
            //First reset height, so we can get the scrollheight
            textArea.style.height = ""
            textArea.style.height = "" + textArea.scrollHeight + "px"
        }

        function NoteInput() {
            if(notesData.isRouteFinished) {
                return (<div className={"text"}>{item.value}</div>)
            }

            return (
                <textarea className={"text"}
                          onChange={(event) => {
                              item.value = event.currentTarget.value;
                              updateTextAreaHeight(event.currentTarget);
                          }}
                          onBlur={() => {
                              if(prevValue !== item.value) {
                                  notesChanged.current = true;
                                  saveNotes();
                              }

                              prevValue = item.value;
                          }}
                          ref={inputRef}
                          defaultValue={item.value}
                />
            )
        }

        return (
            <div className={"note card" + ((notesData.isRouteFinished) ? " disabled" : "")}>
                <div className={"title"}>{item.title}</div>
                <NoteInput />
            </div>
        )
    }

    function Loader() {
        const [isLoading, setLoading] = useState(false);
        setLoadingFunc = setLoading;

        return <LoadingIndicator
            indicatorColor={"light"}
            showBackgroundOverlay={true}
            isFullscreen={true}
            isLoading={isLoading}
        />;
    }

    /**
     * @param {Function|null} onSuccess
     * @param {Function|null} onFailure
     */
    function saveNotes(onSuccess = null, onFailure = null) {
        if(notesData) {
            notesSaveRequest.current?.cancel();

            let newItemsArray = [];
            for(let i = 0; i < notesData.items.length; i++) {
                /** @var {NoteItem} */
                const item = notesData.items[i];
                newItemsArray.push({
                    id: item.id,
                    value: item.value
                })
            }

            notesSaveRequest.current = new NotesSaveRequest(newItemsArray);
            notesSaveRequest.current.onSuccess = () => {
                notesSaveRequest.current = null;
                onSuccess?.();
            }
            notesSaveRequest.current.onFailure = (error) => {
                notesSaveRequest.current = null;
                onFailure?.();
            }
            notesSaveRequest.current.enqueue();
        }
    }

    function finalSaveNotesOrClose() {
        if(notesData && notesChanged.current === true) {
            setLoadingFunc?.(true);
            saveNotes(
                () => {
                    setLoadingFunc?.(false);
                    history.goBack();
                },
                () => {
                    setLoadingFunc?.(false);

                    Popup.showDefaultRequestError(() => {
                        finalSaveNotesOrClose();
                    })
                })
        } else {
            history.goBack();
        }
    }
}

export default Notes;