import {BaseRequest} from "../../api/BaseRequest";
import JsonObject from "../../utils/json/JsonObject";
import CombiboxObject from "../../models/CombiboxObject";

/**
 * @property {Object|Array} jsonData
 * @property {function|null} onSuccess
 * @property {function|null} onFailure
 */
export class ChangeCombiboxRequest extends BaseRequest {
    /**
     * @param {Object|Array} jsonData
     */
    constructor(jsonData) {
        super();

        this.jsonData = jsonData;
        this.onSuccess = null;
        this.onFailure = null;
    }

    createRequest() {
        return {
            method: 'post',
            url: '/overview/change-combibox',
            // url: 'https://zooma.mockable.io/speurroutes/overview/change-combibox',
            headers: this.appUserAccessTokenHeaders(),
            data: this.jsonData
        }
    }

    handleSuccess(response) {
        try {
            const jsonObject = new JsonObject(response.data);
            const combiboxObject = CombiboxObject.parse(jsonObject);

            this.onSuccess?.(combiboxObject);
        } catch(error) {
            this.handleFailure(error);
        }
    }

    handleFailure(error) {
        this.onFailure?.(error);
    }
}