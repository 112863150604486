import React, {useEffect, useRef} from "react";
import './MapView.scss'
import {useHistory} from "react-router-dom";
import mapItemChosenImage from "../../resources/map/map_item_chosen.svg"

function MapView(props) {
    let history = useHistory();

    /** @var {MutableRefObject.<HTMLElement>|null} */
    const scrollingContainer = props.scrollingContainer;
    const currentItemRef = useRef(/** @type {HTMLElement} */ null);

    /** @var {MapData} */
    const data = props.data
    const mapItems = data.items

    useEffect(() => {
        if(scrollingContainer && scrollingContainer.current && currentItemRef.current) {
            //Set timeout otherwise it's too early
            setTimeout(() => {
                currentItemRef.current?.scrollIntoView();
                scrollingContainer.current.scrollTop -= 20;
            }, 0);
        }
    }, []);

    let mapRowsList = [];
    let mapElementList = [];

    function addMapItemsRow() {
        //Key is needed, otherwise we get warning
        mapRowsList.push(<div key={mapRowsList.length} className={"map-items-row"}>
            {mapElementList}
        </div>)

        mapElementList = [];
    }

    for(let mapItemIndex = 0; mapItemIndex < mapItems.length; mapItemIndex++) {
        let mapItem = mapItems[mapItemIndex];
        let chosenImageUrl = (mapItem.isClue() && mapItem.chosenImageUrl) ? mapItem.chosenImageUrl : ""
        //Type to class, so we don't forget to change the css files otherwise
        let mapItemTypeClass = (mapItem.isClue() ? "clue" : (mapItem.isFact() ? "fact" : (mapItem.isSecret() ? "secret" : "normal")))
        //Key is needed, otherwise we get warning
        mapElementList.push(
            <div key={mapItemIndex}
                 className={"map-item" + ((data.currentItemId === mapItem.id) ? " current" : "") + (mapItem.hasAnswer ? " chosen" : "")}
                 ref={((data.currentItemId === mapItem.id) ? currentItemRef : null)}
                 onClick={() => { history.push('/step/' + mapItem.id) }}>
                <div className={"image-container " + mapItemTypeClass}>
                    <div className={"line"} />
                    <MapItemImage hasAnswer={mapItem.hasAnswer} chosenImageUrl={chosenImageUrl}/>
                </div>
                <div className={"title-container"}>
                    <div className={"vertical-line"} />
                    <div className={"title"}>{mapItem.title}</div>
                </div>
            </div>
        )

        function MapItemImage(mapItemImageProps) {
            if(mapItemImageProps.hasAnswer === true) {
                let imageUrl = mapItemChosenImage;
                if(mapItemImageProps.chosenImageUrl && mapItemImageProps.chosenImageUrl !== "") {
                    imageUrl = mapItemImageProps.chosenImageUrl;
                }
                return <div className={"image"} style={{backgroundImage: 'url(' + imageUrl + ')'}} />
            }

            return <div className={"image"} />
        }

        if(mapElementList.length === 3) {
            addMapItemsRow();
        }
    }
    if(mapElementList.length > 0) {
        addMapItemsRow();
    }

    return (
        <div className={"map-view"}>
            {mapRowsList}
        </div>
    );
}

export default MapView;