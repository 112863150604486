import React, {useEffect, useRef, useState} from "react";
import './Step.scss'
import NavigationBar from "../../components/navigation-bar/NavigationBar";
import IconButton from "../../components/IconButton";
import {useHistory} from "react-router-dom";
import {StepRequest} from "./StepRequest";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import ErrorView from "../../components/error-view/ErrorView";
import ImageViewer from "../image-viewer/ImageViewer";
import Popup from "../../components/popup/Popup";
import {StepSaveRequest} from "./StepSaveRequest";
import {StepJokerRequest} from "./StepJokerRequest";
import {CanGoToSolutionRequest} from "./CanGoToSolutionRequest";
import AppStorage from "../../utils/AppStorage";
import {AppUtils} from "../../utils/AppUtils";
import {StepBottomButtonContainer} from "./views/StepBottomButtonContainer";
import {StepGalleryCard} from "./views/StepGalleryCard";
import {StepSolutionsGridCard} from "./views/StepSolutionsGridCard";
import {StepDeactivationCodeCard} from "./views/StepDeactivationCodeCard";

function Step(props) {
    const history = useHistory();
    const stepId = props.match.params.id;
    const stepIdRef = useRef(stepId);
    const stepRequest = useRef(null);
    const stepSaveRequest = useRef(/** @type {StepSaveRequest|null} */ null);
    const stepJokerRequest = useRef(/** @type {StepJokerRequest|null} */ null);
    const canGoToSolutionRequest = useRef(/** @type {CanGoToSolutionRequest|null} */ null);
    const [step, setStep] = useState(/** @type {StepObject} */ null);
    const [requestError, setRequestError] = useState(null);
    const [showImage, setShowImage] = useState(false);
    let setLoadingFunc = null;
    let galleryCardStepUpdatedFunc = null;
    let solutionGridStepUpdatedFunc = null;
    let bottomBarStepUpdatedFunc = null;
    const scrollingContainer = useRef(/** @type {HTMLElement} */ null)
    const scrollingContainerScrollY = useRef(0)

    //If stepId changes, set step to null and cancel all requests
    useEffect(() => {
        stepIdRef.current = stepId;
        if(step) {
            setStep(null);
        }
        scrollingContainerScrollY.current = 0;

        return () => {
            //Happens when component is dismounted, so cancel requests
            stepSaveRequest.current?.cancel();
            stepJokerRequest.current?.cancel();
            canGoToSolutionRequest.current?.cancel();
        }
    }, [stepId]);

    useEffect(() => {
        if(!step && !requestError) {
            stepRequest.current?.cancel();
            stepRequest.current = new StepRequest(stepId);
            stepRequest.current.onSuccess = (step) => {
                setStep(step);
            }
            stepRequest.current.onFailure = () => {
                setRequestError(true);
            }
            stepRequest.current.enqueue();

            return () => {
                //Happens when component is dismounted, so cancel request
                stepRequest.current?.cancel();
            }
        }
    }, [step, requestError])

    useEffect(() => {
        if(scrollingContainer.current && scrollingContainerScrollY.current > 0) {
            scrollingContainer.current.scrollTop = scrollingContainerScrollY.current
        }
    })

    if(step && showImage) {
        return <ImageViewer
            imageUrl={step.imageUrl}
            closeClick={() => {
                setShowImage(false);
            }}
        />;
    }
    return (
        <div id="step" className="main">
            <div className="page-content-wrapper">
                <NavigationBar
                    title={step?.title}
                    transparent={false}
                    leftTitle={"Informatie"}
                    leftIcon={"info"}
                    leftIconClick={() => {
                        history.push('/information')
                    }}
                    rightTitle={"Routekaart"}
                    rightIcon={"map"}
                    rightIconClick={() => {
                        history.push('/overview')
                    }}
                />
                <PageContent />
            </div>
        </div>
    );

    function PageContent() {
        if(requestError) {
            return <ErrorView
                retryOnClick={() => {
                    setRequestError(null);
                }}
            />;
        } else if(step) {
            return <FilledPageContent />;
        } else {
            return <LoadingIndicator
                indicatorColor={"dark"}
                showBackgroundOverlay={false}
                isFullscreen={false}
                isLoading={true}
            />;
        }
    }

    function FilledPageContent() {
        const [isAnimationShown, setAnimationShown] = useState(false);

        useEffect(() => {
            if(!isTransitioningToNewStep() && step.type === "info" && !AppStorage.secretInfoShownIds().includes(stepId)) {
                setAnimationShown(true);
                AppStorage.addSecretInfoShownId(stepId);

                setTimeout(() => {
                    setAnimationShown(false);
                }, 4200);
            }
        }, []);

        return (
            <React.Fragment>
                <div className="page-content">
                    <div className="top-container" ref={scrollingContainer} onScroll={(event) => { scrollingContainerScrollY.current = event.target.scrollTop }}>
                        {step.imageUrl && <div className="cover-image" style={{backgroundImage: 'url(' + step.imageUrl + ')'}} />}
                        <div className="row with-small-padding">
                            {step.imageUrl &&
                            <div className="peek">
                                <IconButton icon={"fullscreen"}
                                            title={"Volledig scherm"}
                                            className={"transparent-flat"}
                                            onClick={() => {
                                                setShowImage(true);
                                            }} />
                            </div>
                            }
                            <div className="card">
                                {step.codeAlphabetButtonTitle && (
                                    <button
                                        className={"inline-button"}
                                        onClick={() => {
                                            history.push('/code-alphabet/' + step.codeAlphabetId)
                                        }}>{step.codeAlphabetButtonTitle}</button>
                                )}
                                <div className={"text"} dangerouslySetInnerHTML={{__html: step.text}} />
                            </div>
                            <StepGalleryCard step={step} saveStep={saveStep} stepUpdated={(stepUpdatedFunc) => { galleryCardStepUpdatedFunc = stepUpdatedFunc }} />
                            <StepSolutionsGridCard step={step} saveStep={saveStep} stepUpdated={(stepUpdatedFunc) => { solutionGridStepUpdatedFunc = stepUpdatedFunc }} />
                            {step.deactivationCode && <StepDeactivationCodeCard data={step.deactivationCode} />}
                        </div>
                    </div>
                    <StepBottomButtonContainer
                        step={step}
                        jokerButtonClicked={jokerButtonClicked}
                        solutionButtonPressed={solutionButtonPressed}
                        stepUpdated={(stepUpdatedFunc) => { bottomBarStepUpdatedFunc = stepUpdatedFunc }}
                    />
                </div>
                {isAnimationShown && <div id={"animation-overlay"}>
                    <img src={require("../../resources/safe.png").default} alt={"Kluis"} />
                </div>}
                <Loader />
            </React.Fragment>
        )
    }

    function Loader() {
        const [isLoading, setLoading] = useState(false);
        setLoadingFunc = setLoading;

        return <LoadingIndicator
            indicatorColor={"light"}
            showBackgroundOverlay={true}
            isFullscreen={true}
            isLoading={isLoading}
        />;
    }

    /**
     * @param {Object|Array} jsonData
     * @param {Function} successCallback
     */
    function saveStep(jsonData, successCallback) {
        setLoadingFunc?.(true);

        function saveStepFailure(error) {
            stepSaveRequest.current = null;
            setLoadingFunc?.(false);

            Popup.showDefaultRequestError(() => {
                saveStep(jsonData, successCallback);
            })
        }

        stepSaveRequest.current?.cancel();
        stepSaveRequest.current = new StepSaveRequest(stepId, jsonData);
        stepSaveRequest.current.onSuccess = (newStepJson) => {
            stepSaveRequest.current = null;
            setLoadingFunc?.(false);
            try {
                //Don't reload entire component
                step.parse(newStepJson);
                successCallback();
            } catch(error) {
                saveStepFailure(error);
            }
        }
        stepSaveRequest.current.onFailure = saveStepFailure;
        stepSaveRequest.current.enqueue();
    }

    function jokerButtonClicked() {
        const config = {
            imageUrl: require("../../resources/joker_popup.svg").default,
            title: "Joker",
            text: "De Joker geeft je direct het juiste antwoord op deze clue. <b>Maar let op: hij biedt zijn diensten tijdens de hele route maar één keer aan!</b><br /><br />Wil je de Joker inzetten?",
            confirmButtonText: "Inzetten",
            cancelButtonText: "Annuleren",
        };
        Popup.show(config,
            () => {
                jokerUsed();
            })
    }

    function jokerUsed() {
        setLoadingFunc?.(true);

        function jokerRequestFailure(error) {
            stepJokerRequest.current = null;
            setLoadingFunc?.(false);

            Popup.showDefaultRequestError(() => {
                jokerUsed();
            })
        }

        stepJokerRequest.current?.cancel();
        stepJokerRequest.current = new StepJokerRequest(stepId);
        stepJokerRequest.current.onSuccess = (newStepJson) => {
            stepJokerRequest.current = null;
            setLoadingFunc?.(false);

            try {
                //Don't reload entire component
                step.parse(newStepJson);
                galleryCardStepUpdatedFunc?.();
                solutionGridStepUpdatedFunc?.();
                bottomBarStepUpdatedFunc?.();

                const jokerMessage = newStepJson.stringDefault("jokerMessage", "");
                if(jokerMessage) {
                    const config = {
                        imageUrl: require("../../resources/joker_popup.svg").default,
                        title: "Let op!",
                        text: jokerMessage,
                        confirmButtonText: "OK"
                    };
                    Popup.show(config);
                }
            } catch(error) {
                jokerRequestFailure(error);
            }
        }
        stepJokerRequest.current.onFailure = jokerRequestFailure;
        stepJokerRequest.current.enqueue();
    }

    function solutionButtonPressed() {
        if(step.isRouteFinished) {
            checkIfCanGoToSolution();
        } else {
            const config = {
                title: "Oplossing versturen",
                text: "Weet je zeker dat je deze oplossing wilt versturen? Je kunt het hierna niet meer wijzigen.",
                confirmButtonText: "Versturen",
                cancelButtonText: "Annuleren"
            };
            Popup.show(config, () => {
                checkIfCanGoToSolution();
            });
        }
    }

    function checkIfCanGoToSolution() {
        setLoadingFunc?.(true);

        canGoToSolutionRequest.current?.cancel();
        canGoToSolutionRequest.current = new CanGoToSolutionRequest();
        canGoToSolutionRequest.current.onSuccess = (canGoToSolution, errorMessage) => {
            canGoToSolutionRequest.current = null;
            setLoadingFunc?.(false);

            if(canGoToSolution === true) {
                AppStorage.setFinishedRoute(true);
                AppUtils.updateRoutes();
                history.push('/solution')
            } else {
                const config = {
                    title: "Oeps...",
                    text: errorMessage ?? "Je hebt nog niet alle clues beantwoord. Bekijk in het Overzicht welke clue(s) dit is/zijn. Wanneer je alle clues hebt opgelost, kun je je oplossing insturen.",
                    confirmButtonText: "OK"
                };
                Popup.show(config);
            }
        }
        canGoToSolutionRequest.current.onFailure = (error) => {
            canGoToSolutionRequest.current = null;
            setLoadingFunc?.(false);

            Popup.showDefaultRequestError(() => {
                checkIfCanGoToSolution();
            })
        }
        canGoToSolutionRequest.current.enqueue();
    }

    /**
     * @return {boolean}
     */
    function isTransitioningToNewStep() {
        if(stepIdRef.current !== stepId) {
            return true;
        }
        return false;
    }
}

export default Step;