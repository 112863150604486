import {BaseRequest} from "../../api/BaseRequest";

/**
 * @property {function|null} onSuccess
 * @property {function|null} onFailure
 * @property {Array} values
 */
export class NotesSaveRequest extends BaseRequest {
    /**
     * @param {Array} values
     */
    constructor(values) {
        super();

        this.onSuccess = null;
        this.onFailure = null;
        this.values = values;
    }

    createRequest() {
        return {
            method: 'post',
            url: '/notes',
            // url: 'https://zooma.mockable.io/speurroutes/notes/save',
            headers: this.appUserAccessTokenHeaders(),
            data: {
                items: this.values
            }
        }
    }

    handleSuccess(response) {
        this.onSuccess?.();
    }

    handleFailure(error) {
        this.onFailure?.(error);
    }
}