import {BaseRequest} from "../../api/BaseRequest";
import JsonObject from "../../utils/json/JsonObject";
import CodeAlphabetData from "../../models/CodeAlphabetData";

/**
 * @property {string} id
 * @property {function|null} onSuccess
 * @property {function|null} onFailure
 */
export class CodeAlphabetRequest extends BaseRequest {
    /**
     * @param {string} id
     */
    constructor(id) {
        super();

        this.id = id;
        this.onSuccess = null;
        this.onFailure = null;
    }

    createRequest() {
        return {
            method: 'get',
            url: '/alphabet/' + this.id,
            // url: 'https://zooma.mockable.io/speurroutes/code-alphabet/' + this.id,
            headers: this.appUserAccessTokenHeaders()
        }
    }

    handleSuccess(response) {
        try {
            const jsonObject = new JsonObject(response.data);
            const codeAlphabetData = CodeAlphabetData.parse(jsonObject);

            this.onSuccess?.(codeAlphabetData);
        } catch(error) {
            this.handleFailure(error);
        }
    }

    handleFailure(error) {
        this.onFailure?.(error);
    }
}