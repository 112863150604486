import {BaseRequest} from "../../api/BaseRequest";
import JsonObject from "../../utils/json/JsonObject";
import RouteObject from "../../models/RouteObject";

/**
 * @property {function|null} onSuccess
 * @property {function|null} onFailure
 */
export class HomeRequest extends BaseRequest {
    constructor() {
        super();

        this.onSuccess = null;
        this.onFailure = null;
    }

    createRequest() {
        return {
            method: 'get',
            url: '/home',
            // url: 'https://zooma.mockable.io/speurroutes/home',
            headers: this.appUserAccessTokenHeaders()
        }
    }

    handleSuccess(response) {
        try {
            const jsonObject = new JsonObject(response.data);
            const routeObject = RouteObject.parse(jsonObject);

            this.onSuccess?.(routeObject);
        } catch(error) {
            this.handleFailure(error);
        }
    }

    handleFailure(error) {
        this.onFailure?.(error);
    }
}