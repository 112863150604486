import React from "react";
import './LetterBoxView.scss'

function LetterBoxView(props) {
    /** @var {String} */
    const value = props.value;

    return (
        <div className={"letter-box" + (props.className ? " " + props.className : "")} onClick={(props.onClick) ? props.onClick : null}>
            <div className={"letter-box-inner"}>
                <div className={"letter-box-value"}>
                    {value}
                </div>
            </div>
        </div>
    )
}

export default LetterBoxView;