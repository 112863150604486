import IconButton from "../../../components/IconButton";
import {useHistory} from "react-router-dom";
import {useReducer} from "react";

export function StepBottomButtonContainer(props) {
    const history = useHistory();
    /** @var {StepObject} **/
    const step = props.step;
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    props.stepUpdated(stepUpdated);

    return (
        <div className="bottom-container">
            <div className="row with-small-padding">
                <div className="buttons left">
                    <LeftButton />
                </div>
                <div className="buttons middle">
                    <JokerButton />
                    <IconButton className={"quaternary"}
                                icon={"notes"}
                                title={"Notities"}
                                onClick={() => {
                                    history.push('/notes')
                                }} />
                </div>
                <div className="buttons right">
                    <RightButton />
                </div>
            </div>
        </div>
    );

    function stepUpdated() {
        forceUpdate();
    }

    function LeftButton() {
        if(step.previousStepId <= 0) {
            return null;
        }
        return (
            <IconButton
                icon={"back"}
                title={"Vorige"}
                onClick={() => {
                    history.push('/step/' + step.previousStepId)
                }} />
        );
    }

    function JokerButton() {
        if(!step.jokerAvailable) {
            return null;
        }
        let jokerOnClick = null;
        let jokerDisabled = false;
        if(step.jokerAvailable === true) {
            jokerOnClick = () => {
                props.jokerButtonClicked();
            }
        } else if(step.jokerAvailable === false) {
            jokerDisabled = true;
        }

        return (
            <IconButton
                className={"tertiary"}
                icon={"joker"}
                title={"Joker"}
                onClick={jokerOnClick}
                disabled={jokerDisabled} />
        );
    }

    function RightButton() {
        if(step.nextStepId > 0) {
            return (
                <IconButton
                    icon={"next"}
                    title={"Volgende"}
                    onClick={() => {
                        history.push('/step/' + step.nextStepId)
                    }} />
            );
        } else {
            return (
                <button
                    className={"smaller"}
                    onClick={() => {
                        props.solutionButtonPressed();
                    }}>Oplossing</button>
            );
        }
    }
}