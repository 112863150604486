import {BaseRequest} from "../../api/BaseRequest";
import OverviewData from "./OverviewData";
import JsonObject from "../../utils/json/JsonObject";

/**
 * @property {function|null} onSuccess
 * @property {function|null} onFailure
 */
export class OverviewRequest extends BaseRequest {
    constructor() {
        super();

        this.onSuccess = null;
        this.onFailure = null;
    }

    createRequest() {
        return {
            method: 'get',
            url: '/overview',
            // url: 'https://zooma.mockable.io/speurroutes/overview',
            // url: 'https://zooma.mockable.io/speurroutes/overview?routeType=spy-mission',
            headers: this.appUserAccessTokenHeaders()
        }
    }

    handleSuccess(response) {
        try {
            const jsonObject = new JsonObject(response.data);
            const overviewData = OverviewData.parse(jsonObject);

            this.onSuccess?.(overviewData);
        } catch(error) {
            this.handleFailure(error);
        }
    }

    handleFailure(error) {
        this.onFailure?.(error);
    }
}