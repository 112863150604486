import GalleryView from "../../../components/gallery-view/GalleryView";
import Popup from "../../../components/popup/Popup";
import {useState} from "react";

export function StepGalleryCard(props) {
    /** @var {StepObject} */
    const step = props.step;
    const [data, setData] = useState(/** @type {GalleryData} */ step.gallery);

    props.stepUpdated(stepUpdated);

    if(!step.gallery) {
        return null;
    }

    return (
        <div className="card gallery">
            <h3>{data.title}</h3>
            <GalleryView
                items={data.items}
                isEnabled={data.isEnabled}
                onItemClick={/** @param {GalleryItem} galleryItem*/(galleryItem) => {
                    if(!galleryItem.crossed || galleryItem.isCrossedCurrentStep) {
                        if(data.isJokerUsedAtSelectedItem()) {
                            const config = {
                                title: "Joker gebruikt",
                                text: "Je hebt de Joker ingezet bij deze clue. De Joker geeft altijd het juiste antwoord. Je kunt het antwoord bij deze clue niet meer wijzigen.",
                                confirmButtonText: "OK"
                            };
                            Popup.show(config);
                        } else {
                            props.saveStep({
                                selectedGalleryItemId: galleryItem.id
                            }, () => {
                                stepUpdated();
                            })
                        }
                    }
                }}
            />
        </div>
    );

    function stepUpdated() {
        setData(step.gallery);
    }
}