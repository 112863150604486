import JsonObject from "../../utils/json/JsonObject";
import MapItem from "./MapItem";

/**
 * @property {int} currentItemId
 * @property {Array.<MapItem>} items
 */
class MapData {

    constructor() {
        this.currentItemId = 0;
        this.items = []
    }

    /**
     * @param {int} currentItemId
     * @param {Array.<MapItem>} items
     * @return {MapData}
     */
    static create(currentItemId, items) {
        let mapData = new MapData();
        mapData.currentItemId = currentItemId
        mapData.items = items
        return mapData;
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.currentItemId = jsonObject.intDefault("currentItemId", 0);
        const itemsJsonArray = jsonObject.array("items");
        this.items = [];
        itemsJsonArray.jsonData.forEach((itemJsonData) => {
            const itemJsonObject = new JsonObject(itemJsonData);
            this.items.push(MapItem.parse(itemJsonObject));
        });
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {MapData}
     */
    static parseFromTab(jsonObject) {
        const mapData = new MapData();
        mapData.parse(jsonObject);
        return mapData;
    }
}

export default MapData;