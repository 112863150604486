/**
 * @property {string} title
 * @property {string} text
 */
class EndObject {
    constructor() {
        this.title = "";
        this.text = "";
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.title = jsonObject.stringDefault("title", "");
        this.text = jsonObject.stringDefault("text", "");
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {EndObject}
     */
    static parse(jsonObject) {
        const endObject = new EndObject();
        endObject.parse(jsonObject);
        return endObject;
    }
}

export default EndObject;