/**
 * @property {string} city
 * @property {string} type
 * @property {string} imageUrl
 * @property {string} text
 * @property {string} distance
 * @property {string} duration
 * @property {int} firstStepId
 */
class RouteObject {
    constructor() {
        this.type = "";
        this.city = "";
        this.imageUrl = "";
        this.text = "";
        this.distance = "";
        this.duration = "";
        this.firstStepId = 0;
    }

    static fillTestData() {
        let route = new RouteObject();
        route.city = "Delft";
        route.type = "treasure-hunt";
        route.text = "<p>Ontdek met deze Speurroute de mooiste plekjes van Delft. Tijdens deze wandeling blijven de Oude en de Nieuwe Kerk als twee trouwe bakens voortdurend in zicht, terwijl een heleboel andere Delftse iconen om jouw aandacht vechten. Wat dacht je van het Prinsenhof, dat vol staat met onze Vaderlandse geschiedenis, de Markt met zijn schitterende gebouwen, de schilder Johannes Vermeer die het stadsbeeld van weleer heeft vastgelegd en natuurlijk het Delfts blauw.</p>";
        route.imageUrl = require("../resources/example-cover.png").default
        route.distance = "3,5 km"
        route.duration = "150 min"
        route.firstStepId = 1;
        return route
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.type = jsonObject.stringDefault("type", "");
        this.city = jsonObject.stringDefault("city", "");
        this.imageUrl = jsonObject.stringDefault("imageUrl", "");
        this.text = jsonObject.stringDefault("text", "");
        this.distance = jsonObject.stringDefault("distance", "");
        this.duration = jsonObject.stringDefault("duration", "");
        this.firstStepId = jsonObject.int("firstStepId");
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {RouteObject}
     */
    static parse(jsonObject) {
        const routeObject = new RouteObject();
        routeObject.parse(jsonObject);
        return routeObject;
    }
}

export default RouteObject;