import React from "react";
import './NavigationBar.scss'
import IconButton from "../IconButton";
import AppStorage from "../../utils/AppStorage";

function NavigationBar(props) {
    function NavigationBarIcon(props) {
        return props.icon ?
            <IconButton className={"transparent"} icon={props.icon} onClick={props.onClick} title={props.title} />
            : null
    }

    let extraClasses = "";
    switch(AppStorage.routeType()) {
        case "treasure":
            extraClasses += "orange";
            break;
        case "spy":
            extraClasses += "green";
            break;
        default: //e.g. "murder"
            extraClasses += "blue";
            break;
    }

    return (
        <div id="navigation-bar" className={(props.transparent === true) ? "transparent" : extraClasses}>
            <div id="navigation-bar-content" className="row with-small-padding">
                <div className={"icon-container"}>
                    <NavigationBarIcon icon={props.leftIcon} onClick={props.leftIconClick} title={props.leftTitle} />
                </div>
                <h2>{props.title}</h2>
                <div className={"icon-container"}>
                    <NavigationBarIcon icon={props.rightIcon} onClick={props.rightIconClick} title={props.rightTitle} />
                </div>
            </div>
        </div>
    );
}

export default NavigationBar;